import HtDialog from '../../standard/dialog';
import RegisterBody from './components/register-body';

const { openHtDialog } = HtDialog;

function RegisterDialog(props) {
  const { visible, onClose, intl } = props;

  return (
    <HtDialog
      visible={visible}
      onClose={onClose}
      title={intl.formatMessage({
        defaultMessage: '新用户注册',
        id: '451ebf7aeb904ecc90d93cce8db991f1',
      })}
    >
      <RegisterBody intl={intl} />
    </HtDialog>
  );
}

export default RegisterDialog;

export const openRegisterDialog = (props) => {
  const { theme, intl, withMobileLogin, isFromAffiliate } = props;
  const dialogId = 'openRegisterDialog';
  openHtDialog({
    theme,
    dialogId,
    title: intl.formatMessage({
      defaultMessage: '新用户注册',
      id: '451ebf7aeb904ecc90d93cce8db991f1',
    }),
    style: {
      width: '540px',
    },
    maskClosable: false,
    children: (
      <RegisterBody
        intl={intl}
        withMobileLogin={withMobileLogin}
        isFromAffiliate={isFromAffiliate}
        theme={theme}
        dialogId={dialogId}
      />
    ),
  });
};
