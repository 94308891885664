import styled from 'styled-components';

export const Styled = styled.div`
  .pic-adv-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    margin: auto;
    padding: var(--pm-80-64-32) 0;
    box-sizing: border-box;
    .icon-close {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      background-color: rgba(255, 255, 255, 0.8);
      border-radius: 50%;
      margin-left: auto;
      &.bottom-center {
        order: 1;
        margin-right: auto;
      }
      svg {
        fill: rgba(0, 0, 0, 0.8);
        width: 20px;
        height: 20px;
      }
    }
    .pic-adv-cover {
      display: block;
      max-height: calc(100vh - var(--pm-80-64-32) - var(--pm-80-64-32) - 36px);
      object-fit: contain;
      &.h5-adv {
        display: none;
      }
    }
    @media screen and (max-width: 750px) {
      .pic-adv-cover {
        &.pc-adv {
          display: none;
        }
        &.h5-adv {
          display: block;
          max-width: 100%;
        }
      }
    }
  }
`;
