import React, { useMemo } from 'react';
import { DialogStyle } from './style';
import { isEmpty } from 'lodash';
import HtDialog from 'components/standard/dialog';
import { Button } from 'zent';
import { ImageBox } from '../../goods-item';
import { isLimitGoodsAttach } from 'helper/cart';

const CART_STATUS_OK = 'ok'; // 有库存

export const CartGoodsLimitRemindDrawer = (props) => {
  const { $fm, visible, setVisible, cartList, hasLimited } = props;

  const onClose = () => {
    setVisible(false);
  };

  // const { minLimitList, maxLimitGroup } = useMemo(() => {
  //   const minLimitList: any[] = [];
  //   const goodsGroup = {}; // 限购是商品级别的, 需要根据商品维度分组, 并计算所有规格总数
  //   for (const item of cartList) {
  //     const { minPurchaseQty, limitInfo, quantity, giftGoodsFlag } = item;
  //     if (giftGoodsFlag === 1) continue; // 赠品不在限购范围
  //     if (minPurchaseQty && minPurchaseQty > 1 && quantity < minPurchaseQty) {
  //       minLimitList.push(item);
  //     }
  //     if (!isEmpty(limitInfo)) {
  //       if (!goodsGroup[item.goodsId]) {
  //         goodsGroup[item.goodsId] = {
  //           goodsId: item.goodsId,
  //           title: item.title,
  //           quantity: 0,
  //           limitRule: item.limitInfo,
  //           list: [],
  //         };
  //       }
  //       goodsGroup[item.goodsId].list.push(item);
  //       goodsGroup[item.goodsId].quantity += item.quantity;
  //     }
  //   }
  //   // 商品规格总数大于限购数, 不可结算
  //   const maxLimitGroup = {};
  //   for (const goodsId in goodsGroup) {
  //     const goodsItem = goodsGroup[goodsId];
  //     if (goodsItem.quantity > goodsItem.limitRule.quota) {
  //       maxLimitGroup[goodsId] = goodsItem;
  //     }
  //   }

  //   hasLimited.current = minLimitList.length > 0 || Object.keys(maxLimitGroup).length > 0;
  //   return {
  //     minLimitList,
  //     maxLimitGroup,
  //   };
  // }, [cartList]);
  const { minLimitList, maxLimitGroup, attachLimitList } = useMemo(() => {
    const minLimitList: any[] = [];
    const attachLimitList: any[] = [];
    const goodsGroup = {}; // 限购是商品级别的, 需要根据商品维度分组, 并计算所有规格总数
    for (const item of cartList) {
      const { goodsStatus, minPurchaseQty, limitRule, quantity, giftGoodsFlag, attachProps, attachPropRules } = item;
      // 商品下架失效或者赠品不在限购范围
      if (giftGoodsFlag === 1 || goodsStatus !== CART_STATUS_OK) continue;
      if (minPurchaseQty && minPurchaseQty > 1 && quantity < minPurchaseQty) {
        minLimitList.push(item);
      }
      if (!isEmpty(limitRule)) {
        if (!goodsGroup[item.goodsId]) {
          goodsGroup[item.goodsId] = {
            goodsId: item.goodsId,
            title: item.title,
            quantity: 0,
            limitRule: item.limitRule,
            list: [],
          };
        }
        goodsGroup[item.goodsId].list.push(item);
        goodsGroup[item.goodsId].quantity += item.quantity;
      }
      // 附加项数量判断
      if (!isEmpty(attachProps) && !isEmpty(attachPropRules)) {
        const { isLimit, errorKey, errorMsg } = isLimitGoodsAttach(attachProps, attachPropRules);
        if (isLimit) {
          attachLimitList.push({
            ...item,
            errorMsg: $fm(errorKey, errorMsg),
          });
        }
      }
    }
    // 商品规格总数大于限购数, 不可结算
    const maxLimitGroup = {};
    for (const goodsId in goodsGroup) {
      const goodsItem = goodsGroup[goodsId];
      if (goodsItem.quantity > goodsItem.limitRule.quota) {
        maxLimitGroup[goodsId] = goodsItem;
      }
    }

    hasLimited.current = minLimitList.length > 0 || Object.keys(maxLimitGroup).length > 0 || attachLimitList.length > 0;
    return {
      minLimitList,
      maxLimitGroup,
      attachLimitList,
    };
  }, [cartList]);

  return (
    <HtDialog title={$fm('5ed8bfee8d024b1b883a84b8e80c34a8', '提醒')} visible={visible} onClose={onClose}>
      <DialogStyle />
      <div className='remindr-drawer-body'>
        {minLimitList.length > 0 && (
          <div className='min-limit'>
            <div className='limit-tips'>
              {$fm('2d5c47104296400f8d385d0a63b3e41f', '以下商品有“{title}”限制，请修改购买数量', {
                title: $fm('f81a58696cb2439a98b14adc54462d85', '起购'),
              })}
            </div>
            <div className='limit-goods-list'>
              {minLimitList.map((item) => (
                <div className='limit-goods-item'>
                  <ImageBox
                    className='goods-cover'
                    imageUrl={item.imageUrl}
                    style={{
                      width: '80px',
                      height: '80px',
                      flexShrink: 0,
                    }}
                  />
                  <div className='limit-goods-item__info'>
                    <div className='info-name'>{item.title}</div>
                    {item?.sku?.length > 0 && <div className='info-sku'>{item.sku.join?.('; ')}</div>}
                    <div className='info-num'>
                      <div>×{item.quantity}</div>
                      <div className='info-min-purchase-num'>
                        {$fm('a71458ecc92a4a16ab72cd6733240964', '{num}件起购', { num: item.minPurchaseQty || 1 })}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {Object.keys(maxLimitGroup).length > 0 && (
          <div className='max-limit'>
            <div className='limit-tips'>
              {$fm('2d5c47104296400f8d385d0a63b3e41f', '以下商品有“{title}”限制，请修改购买数量', {
                title: $fm('36ab2243c74144de84008369eed85f75', '限购'),
              })}
            </div>
            {Object.keys(maxLimitGroup).map((goodsId) => {
              const { limitRule, list } = maxLimitGroup[goodsId];
              return (
                <div className='limit-goods-list'>
                  <div className='max-limit-tips'>
                    {limitRule.limitPeriod === 'day' &&
                      $fm('3b80752500c54fe2a44f9d669a785743', '该商品每天总数限购{num}件', { num: limitRule.quota })}
                    {limitRule.quantityLimitType === 'order' &&
                      $fm('f2d93f10775041d488c436934a407f49', '该商品每单限购{num}件', { num: limitRule.quota })}
                  </div>
                  {list.map((item) => (
                    <div className='limit-goods-item'>
                      <ImageBox
                        className='goods-cover'
                        imageUrl={item.imageUrl}
                        style={{
                          width: '80px',
                          height: '80px',
                          flexShrink: 0,
                        }}
                      />
                      <div className='limit-goods-item__info'>
                        <div className='info-name'>{item.title}</div>
                        {item?.sku?.length > 0 && <div className='info-sku'>{item.sku.join?.('; ')}</div>}
                        <div className='info-num'>×{item.quantity}</div>
                      </div>
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
        )}
        {attachLimitList.length > 0 && (
          <div className='min-limit attach-props'>
            <div className='limit-tips'>
              {$fm('de75b87fdc3645ee9424aee7a5cbdee7', '以下商品的选项中有“数量”限制，请返回重新选择规格')}
            </div>
            <div className='limit-goods-list'>
              {attachLimitList.map((item) => {
                const attachPropsText = item.attachProps
                  .map((attachProp) => {
                    return attachProp.values
                      .map((item) => {
                        const { value, quantity } = item;
                        return `${value} x ${quantity}`;
                      })
                      .join('; ');
                  })
                  .join('; ');
                const hasSku = item?.sku?.length > 0;
                return (
                  <div className='limit-goods-item'>
                    <ImageBox
                      className='goods-cover'
                      imageUrl={item.imageUrl}
                      style={{
                        width: '80px',
                        height: '80px',
                        flexShrink: 0,
                      }}
                    />
                    <div className='limit-goods-item__info'>
                      <div className='info-name'>{item.title}</div>
                      {(hasSku || !!attachPropsText) && (
                        <div className='info-sku'>{`${item.sku.join?.('; ')}
                        ${hasSku ? '; ' : ''}${attachPropsText}`}</div>
                      )}
                      <div className='info-num'>
                        <div>×{item.quantity}</div>
                        <div className='info-min-purchase-num'>{$fm(item.errorKey, item.errorMsg)}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <Button className='back-btn' type='primary' onClick={onClose}>
          {$fm('2ef74bd0d3264ab4865cba1f77b2d619', '返回修改')}
        </Button>
      </div>
    </HtDialog>
  );
};
