/* eslint-disable prettier/prettier */
// 订单状态枚举
export enum ORDER_STATUS {
  /** 待付款状态 */
  WAIT_PAYMENT = 'wait_payment',
  /** 待确认状态 */
  UNCONFIRMED = 'unconfirmed',
  /** 支付中状态 */
  PAYING = 'paying',
  /** 等待中；已付款暂不发货的三方支付状态 */
  WAITING = 'waiting',
  /** 待发货, 已经生成履约单状态 */
  WAIT_FULLFILLMENBT = 'wait_fulfillment',
  /** 已取消状态 */
  CANCELED = 'canceled',
  /** 待收款状态 */
  WAIT_RECEIVED = 'wait_received',
  /** 已发货状态 */
  FULFILLED = 'fulfilled',
  /** 已部分发货状态 */
  PART_FULFILLED = 'partial_fulfillment',
  /** 退款中状态 */
  REFUNDING = 'refunding',
  /** 已退款状态 */
  REFUNDED = 'refunded',
  /** 退款失败状态 */
  REFUND_FAILURE = 'refund_failure',
  /** 任何状态，用于查询 */
  ANY = 'any',
  /** 未支付状态，用于查询 */
  UNPAID = 'unpaid',
  /** 已付款状态 */
  PAID = 'paid',
  /** 已完成状态 */
  COMPLETED = 'completed',
  /** 部分退款成功状态 */
  PARTIALLY_REFUNDED = 'partially_refunded',
}

// 订单状态配置
export const OrderStatusConfig = {
  [ORDER_STATUS.WAIT_PAYMENT]: {
    id: ORDER_STATUS.WAIT_PAYMENT,
    name: '待付款',
    key: 'order.pending', // 国际化id
  },
  [ORDER_STATUS.UNCONFIRMED]: {
    id: ORDER_STATUS.UNCONFIRMED,
    name: '待确认',
    key: 'order_unconfirmed',
    tipMessage: {
      id: '366161cf9e1f4ca19e9bb68b9f0eca0d',
      defaultMessage: '商家确认收款中，如果商家长时间未确认，请联系商家咨询情况',
    }
    // tagMap: RateOptions.EMPTY,
  },
  [ORDER_STATUS.PAYING]: {
    id: ORDER_STATUS.PAYING,
    name: '支付中',
    key: 'pay_pending',
    tipMessage: {
      id: '4cb92c9a5ab04526921306ed35c78c5e',
      defaultMessage: '支付处理中，请不要重复付款',
    }
    // tagMap: RateOptions.EMPTY,
  },
  [ORDER_STATUS.WAITING]: {
    id: ORDER_STATUS.WAITING,
    name: '待成团',
    key: '5c8e66351d7e45419b6f4fda4c96620f',
    tipMessage: {
      id: 'b940428bd54744198944b3781f280508',
      defaultMessage: '未成团，请稍候',
    }
    // tagMap: RateOptions.EMPTY,
  },
  [ORDER_STATUS.WAIT_FULLFILLMENBT]: {
    id: ORDER_STATUS.WAIT_FULLFILLMENBT,
    name: '待发货',
    key: 'order.waitSend',
    tipMessage: {
      id: 'order.waitSendTip',
      defaultMessage: '已通知卖家发货，请耐心等待',
    }
    // tagMap: RateOptions.EMPTY_ORANGE,
  },
  [ORDER_STATUS.CANCELED]: {
    id: ORDER_STATUS.CANCELED,
    name: '已取消',
    key: '8537855511464194ac8a2751f4ac55d4',
    // tagMap: RateOptions.FULL,
  },
  [ORDER_STATUS.WAIT_RECEIVED]: {
    id: ORDER_STATUS.WAIT_RECEIVED,
    name: '待付款',
    key: 'order.pending',
    tipMessage: {
      id: '6bd94c40a5d94ea9b7213e4294fde656',
      defaultMessage: '商家已发货，请在包裹签收时进行支付',
    }
    // tagMap: RateOptions.EMPTY_GREEN,
  },
  [ORDER_STATUS.FULFILLED]: {
    id: ORDER_STATUS.FULFILLED,
    name: '已发货',
    key: 'order.detail.has_send',
    tipMessage: {
      id: '5ec4722869d74984b38864ca76d4a6f8',
      defaultMessage: '包裹已发货，请等待收货',
    }
    // tagMap: RateOptions.FULL,
  },
  [ORDER_STATUS.REFUNDING]: {
    id: ORDER_STATUS.REFUNDING,
    name: '退款中',
    key: '01a2f5580d834f05b17c005caf081005',
    tipMessage: {
      id: '641982ae94c749f98aa1640272c994ba',
      defaultMessage: '三方服务商退款中，请稍后刷新查看退款结果',
    }
    // tagMap: RateOptions.EMPTY,
  },
  [ORDER_STATUS.REFUNDED]: {
    id: ORDER_STATUS.REFUNDED,
    name: '已退款',
    key: 'order.refunded',
    tipMessage: {
      id: '81259f81de9e4c989ef8ae310376335c',
      defaultMessage: '订单已退款，如未收到退款，请联系商家',
    }
    // tagMap: RateOptions.FULL,
  },
  [ORDER_STATUS.REFUND_FAILURE]: {
    id: ORDER_STATUS.REFUND_FAILURE,
    name: '退款失败',
    key: 'f1d66b5c48154d42868efb8855616d62',
    tipMessage: {
      id: '8fa53557aaaa49afabfbb57f18d6f59c',
      defaultMessage: '订单退款失败，请联系商家，人工处理退款',
    }
    // tagMap: RateOptions.FULL_RED,
  },
  [ORDER_STATUS.COMPLETED]: {
    id: ORDER_STATUS.COMPLETED,
    name: '已完成',
    key: '279d0d7123ae4163828c0a5ec9a33ab3',
    tipMessage: {
      id: '7cb03dd8005b46999955ca08c8f85626',
      defaultMessage: '订单已完成收货，如果有疑问，建议联系商家咨询情况',
    }
  },
  [ORDER_STATUS.PART_FULFILLED]: {
    id: ORDER_STATUS.PART_FULFILLED,
    name: '已部分发货',
    key: 'PartialDelivery',
  },
};

// 支付状态枚举
export enum ORDER_PAYMENT_STATUS {
  PENDING = 'pending', // 待付款
  // 新增支付中（原已授权包装到此状态）
  PAYING = 'paying', // 支付中
  UNCONFIRMED = 'unconfirmed', // 待确认
  PAID = 'paid', // 已付款
  CANCELED = 'canceled', // 已取消 新增
  REFUNDING = 'refunding', // 退款中 新增
  REFUNDED = 'refunded', // 原为已退款，现为退款成功
  REFUND_FAILURE = 'refund_failure', // 退款失败 新增
  PARTIALLY_REFUNDED = 'partially_refunded', // 原为已部分退款，现为部分退款成功

  AUTHORIZED = 'authorized', // 原授权，现被包装到了支付中，所以将不会被使用
  PARTIALLY_PAID = 'partially_paid', // 部分付款。现有需求暂未使用
  VOIDED = 'voided', // 已撤销，暂未使用上

  // 这里也搞不明白后端为啥查询状态和数据状态不一致
  ANY = 'any', // 任何，查询状态
  UNPAID = 'unpaid', //  未支付,查询状态
}

// 支付状态多语言map
export const orderPaymentStatusMap = {
  // 新增
  [ORDER_PAYMENT_STATUS.PAYING]: 'pay_pending',
  [ORDER_PAYMENT_STATUS.CANCELED]: '8537855511464194ac8a2751f4ac55d4',
  [ORDER_PAYMENT_STATUS.REFUNDING]: '01a2f5580d834f05b17c005caf081005',
  [ORDER_PAYMENT_STATUS.REFUND_FAILURE]: 'f1d66b5c48154d42868efb8855616d62',

  [ORDER_PAYMENT_STATUS.PARTIALLY_PAID]: 'order.partially_paid',
  [ORDER_PAYMENT_STATUS.PARTIALLY_REFUNDED]: '30fe8e8ac66f4dceabd43f7e630e6303',
  [ORDER_PAYMENT_STATUS.REFUNDED]: 'b880357324894ea1934bfa93f471b108',
  [ORDER_PAYMENT_STATUS.PAID]: 'order.paid',
  [ORDER_PAYMENT_STATUS.UNPAID]: 'order.pending',
  [ORDER_PAYMENT_STATUS.PENDING]: 'order.pending',
  [ORDER_PAYMENT_STATUS.AUTHORIZED]: 'pay_pending',
  [ORDER_PAYMENT_STATUS.VOIDED]: 'order.voided',
  [ORDER_PAYMENT_STATUS.UNCONFIRMED]: 'order_unconfirmed',
};

export const DELIVERY_STATUS = {
  FULFILLED: 'fulfilled', // 已发货
  NULL: 'none', // 未发货
  PARTIALLY: 'partial', // 部分发货
  NOT_REQUIRES: 'not_requires', // 无需发货
  HOLD: 'hold', // 暂时不发货 -比如待拼团状态
};

export const DeliveryStatusMap = {
  [DELIVERY_STATUS.FULFILLED]: 'order.detail.has_send',
  [DELIVERY_STATUS.NULL]: 'order.detail.un_send',
  [DELIVERY_STATUS.HOLD]: 'order.detail.un_send',
  [DELIVERY_STATUS.PARTIALLY]: 'order.detail.partial',
  [DELIVERY_STATUS.NOT_REQUIRES]: '4d84e69cef40454d82c5baf46ceb2c49',
};

export const SUPPORT_LANGUAGE = {
  zhCN: 'zh-CN',
  zhTM: 'zh-TW',
  JA: 'ja',
  EN: 'en',
};

export const LOGIN_CHANNELS = {
  facebook: 'facebook',
  google: 'google',
  line: 'line',
  thirdApp: 'third-app',
};

export const payMethods = {
  PAYPAL: 1,
  STRIPE: 2,
  ALLINPAY: 3,
  CLOVER: 22,
};

// 分享链接中折扣码的cookie名称
export const DISCOUNT_CODE = 'discount_code';

/** 页面打开模式 */
export const OPEN_MODE = {
  PREVIEW_SIMPLE: 1,
  PREVIEW_NORMAL: 2,
  EDIT_NORMAL: 3,
};

export const RESERVE_ENTRY_ENUM = {
  GOODS_DETAIL: 10,
  ORDER_DETAIL: 20,
  EMAIL: 30,
};

export const CAPTCHA_SITE_KEY = '6LdEez8aAAAAAKTYLMZJkrmg5EN3P1AKPbzYPP3m';

// sessionStorage 中使用
export const IS_OPEN_LOGIN = 'is_open_login';
export const SHOP_LOGO_URL = 'shop_logo_url';

// 店铺状态
export const STORE_STATUS = {
  VALID: 'valid',
  CLOSING: 'closing',
  PROTECTION: 'protection',
};

// 是否开启主域名跳转
export const MAIN_DOMAIN_REDIRECT_STATUS = {
  OPEN: 'open',
};

export const POP_THEME_TYPE = {
  EMAIL_SUBSCRIBE: 'email_subscribe', // 邮件订阅
  DISCOUNT_ACTIVITY: 'discount_activity', // 折扣活动
  ACTIVITY_NOTE: '', // 活动通知 这个类型的弹窗还没开发，先不用关注
  WARM_NOTE: 'warm_note', // 政策
  PIC_ADV: 'pic_adv', // 图片广告
};
export const MessageType = {
  CLIENT_CHANGE: 'clientChange',
  ADMIN_CHANGE: 'adminChange',
  CURRENT_SECTION: 'currentSection',
  HASH_CHANGE: 'hashChange',
  SECTIONSELECT: 'cToB::sectionSelect',
  EDIT_INFO: 'edit_info',
  SECTION_MOVE_UP: 'section_move_up',
  SECTION_MOVE_DOWN: 'section_move_down',
  SECTION_DELETE: 'section_delete',
  SECTION_COPY: 'section_copy',
  SITE_SWITCH: 'site_switch',
  TEMPLATE_SAVE: 'template_save',
  DISABLEJS: 'disable_js',
};

export const GOODS_STATUS_SELLING = {
  UP: 1,
  DOWN: 2,
};

export const DEFAULT_IMAGE_SRC = 'https://img01.yzcdn.cn/upload_files/2021/08/16/FmjmzgML0mx3cCH8SvTQ2xvcopVV.png';

export const COD_METHOD_NAME = 'cod';

export const TRACK = {
  AddCartButton: 'track-add-cart-button',
  BuyOnceButton: 'track-buy-once-button',
};

export const FIXED_SECTIONS = [
  'announcement-bar',
  'header',
  'footer',
  'member-info',
  'member-discount',
  'member-reward',
  'member-goods',
  'member-slide',
];

export const COLLECTION_PAGE_SECTIOCNS = ['collection-cover-pic', 'collection-template'];

/** 装修数据类型 */
export const ThemeDataType = {
  MemberThemeData: 'memberThemeData',
  ThemeData: 'themeData',
};

export const HEAD_ICON_TYPE = {
  cart: 'head-icon-cart',
  wish: 'head-icon-wish',
};

export const CALLBACK_TYPE = {
  line: 'line',
};

export const SWITCH_OPEN_STATUS = {
  OPEN: 'open',
  CLOSE: 'close',
};

export const SWITCH_OPEN_STATUS_ON_OFF = {
  on: 'on',
};

export const UTM_KEYS_MAP = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'];

export const MEDIA_TYPE = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
};

export const DELIVERY_METHOD = {
  local_delivery: 'local_delivery',
  shipping_method: 'shipping_method',
  pickup: 'pickup',
};

export enum GOODS_FEATURE_TYPE {
  physical = 'physical', // 实物商品
  virtual = 'virtual',  // 虚拟商品
  newService = 'new_service', // 服务商品
  eCard = 'e_card',// 卡券商品
}

export enum ECARD_GOODS_STATUS_STR {
  /** 可售 */
  saleable = 'saleable',
  /** 过期 */
  expired = 'expired',
}

export enum ECARD_VERIFY_STATUS {
  /** 默认值 */
  none = 'none',
  /** 未使用 */
  notVerify = 'not_verify',
  /** 已使用 */
  verified = 'verified',
  /** 已过期 */
  expired = 'expired',
  /** 已失效 */
  disabled = 'disabled',
}

// 券码状态文案
export const ecardVerifyStatusText = {
  [ECARD_VERIFY_STATUS.notVerify]: {
    id: 'order_service_wait_use',
    defaultMessage: '待使用',
  },
  [ECARD_VERIFY_STATUS.verified]: {
    id: 'coupons.used',
    defaultMessage: '已使用',
  },
  [ECARD_VERIFY_STATUS.expired]: {
    id: 'coupons.overtime',
    defaultMessage: '已过期',
  },
  [ECARD_VERIFY_STATUS.disabled]: {
    id: 'e8de21460d154f6d952301627bbbbe07',
    defaultMessage: '已失效',
  },
};