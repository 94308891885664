import React, { useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { intlLiToYuan } from 'utils/money';
import DefaultImg from '../../default-img';
import { Popover, Button, Dialog } from 'zent';
import SkuPageV2 from '../../sku-page-v2';
import MultiCurrencyContext from '../../../context/multi-currency';
import { useI18n } from 'hook/intl';
import cn from 'classnames';

import {
  MultipleSpecificationsStyle,
  PriceItemStyle,
  DiscountInfoStyle,
  ImgContainerStyle,
  GoodsTitleStyle,
  DeleteBtnStyle,
  TagStyle,
  SkuLineStyle,
} from './style';

const { openDialog, closeDialog } = Dialog;

/** 缺少个售罄状态 */
export const ImageBox = ({
  imageUrl,
  quantity,
  isExpiredGoodsStatus,
  isSidebar,
  style,
  handle,
  className,
  cartStatus,
}) => {
  const intl = useIntl();
  const { $fm } = useI18n(intl);

  return (
    <ImgContainerStyle style={style} className={className}>
      {/* <div className="image-content-wrapper"> */}
      {imageUrl ? <img className='img' src={imageUrl} alt={handle} style={style} /> : <DefaultImg square width='50%' />}
      {quantity === 0 && !isExpiredGoodsStatus && (
        <>
          {isSidebar && (
            <div className='sidebar-status'>
              <span className='tip-text'>{$fm('goods.tips.none_sales', '已售罄')}</span>
            </div>
          )}
          {!isSidebar && <div className={cn('sold-out-tips')}>{$fm('goods.tips.none_sales', '已售罄')}</div>}
        </>
      )}
      {cartStatus?.coverErrorTips && (
        <>
          {isSidebar && (
            <div className='sidebar-status'>
              <span className='tip-text'>{$fm(cartStatus?.coverErrorTips)}</span>
            </div>
          )}
          {!isSidebar && <div className={cn('sold-out-tips')}>{$fm(cartStatus?.coverErrorTips)}</div>}
        </>
      )}
      {isExpiredGoodsStatus && isSidebar && (
        <div className='sidebar-status'>
          <span className='tip-text'>{$fm('e8de21460d154f6d952301627bbbbe07', '已失效')}</span>
        </div>
      )}
      {/* </div> */}
    </ImgContainerStyle>
  );
};
export const GoodsTitle = ({ title, lineClamp = 2 }) => {
  return (
    <GoodsTitleStyle className='goods-name' lineClamp={lineClamp}>
      {title}
    </GoodsTitleStyle>
  );
};

export const SkuLine = ({ optionTitles = [], optionValues = [], attachProps = [] }) => {
  const optionValuesLength = optionValues?.length;
  const attachPropsLength = attachProps?.length;

  const attachPropsText = attachProps
    .map((attachProp) => {
      return attachProp.values
        .map((item) => {
          const { value, quantity } = item;
          return `${value} x ${quantity}`;
        })
        .join(' / ');
    })
    .join(' / ');
  return (
    <SkuLineStyle>
      <div className='sku-line-wrapper'>
        {attachPropsLength !== 0 || optionValuesLength !== 0 ? (
          <>
            {optionValuesLength !== 0 && (
              <span className='sku-line-options-values'>
                {optionValues?.map((item, index) => {
                  if (item?.typeStr === 'color_switch') {
                    return (
                      <div className='options-value-item' key={item?.title + index}>
                        <div
                          className={cn(
                            'options-value-item-color',
                            `options-value-item-color-length-${item?.variantSkuColorOptionValue?.colorCode?.length}`,
                          )}
                        >
                          {item?.variantSkuColorOptionValue?.colorCode.map((colorItem) => {
                            return (
                              <span
                                className='options-value-color-half'
                                key={colorItem}
                                style={{ backgroundColor: colorItem }}
                              />
                            );
                          })}
                        </div>
                      </div>
                    );
                  }
                  return (
                    <span className='options-value-item' key={item?.title + index}>
                      {item?.title}
                    </span>
                  );
                })}
              </span>
            )}
            {attachPropsLength !== 0 && (
              <span className='attach-items'>
                {optionValuesLength !== 0 ? <>&nbsp;/&nbsp;</> : ''}
                {attachPropsText}
              </span>
            )}
          </>
        ) : (
          <span className='sku-line-options-titles'>
            {typeof optionTitles === 'string' ? optionTitles : optionTitles?.join('; ')}
          </span>
        )}
      </div>
    </SkuLineStyle>
  );
};

// 规格
export const MultipleSpecifications = (props) => {
  const {
    optionTitles = [],
    optionValues = [],
    className,
    canChangeSku,
    goodsId,
    preVariantId,
    updateCartList,
    quantity,
    canDelete,
    isSidebar,
    onCartSkuChange,
    type,
    attachProps = [],
    cartStatus,
    domClassName,
    preItemKey = '',
  } = props;
  const intl = useIntl();
  const [skuPageShow, setSkuPageShow] = useState(false);
  const onChangeSku = () => {
    // if (!canChangeSku || !canDelete || isSidebar) return;
    if (canChangeSku && canDelete) {
      setSkuPageShow(true);
    }
  };
  const noAttachProps = !attachProps?.length;
  const noOptions = !optionTitles?.length;
  if (noAttachProps && noOptions) return null;

  return (
    <MultipleSpecificationsStyle className={cn(className, { leakage: type === 'leakage' })}>
      <div onClick={onChangeSku}>
        {skuPageShow && canDelete && (
          <SkuPageV2
            goodsId={goodsId}
            setSkuPageShow={setSkuPageShow}
            preVariantId={preVariantId}
            preItemKey={preItemKey}
            updateCartList={updateCartList}
            quantity={quantity}
            onCartSkuChange={onCartSkuChange}
            selectedAttachProps={attachProps}
            domClassName={domClassName}
          />
        )}
        {(!!attachProps?.length || !!optionTitles?.length || !!optionValues?.length) && (
          <div
            className={cn(
              'size',
              'custom-sku-wrapper',
              `size-show-border-${!!canChangeSku && !!canDelete && !isSidebar}`,
            )}
          >
            {cartStatus?.resetSkuSelected ? (
              intl.formatMessage({ id: '6f5a972e20344aeba03186acf1d34a90', defaultMessage: '重新选择规格' })
            ) : (
              <SkuLine optionTitles={optionTitles} optionValues={optionValues} attachProps={attachProps} />
            )}
            {canChangeSku && canDelete && !isSidebar && (
              <span className='svg-wrapper'>
                <svg width={16} height={16}>
                  <use xlinkHref='#iconic-xiala' />
                </svg>
              </span>
            )}
          </div>
        )}
      </div>
    </MultipleSpecificationsStyle>
  );
};

/**
 * price 原价
 * discount 折扣金额
 * type 样式类型，两个价格是上下布局 / 左右布局 row｜column
 * discount 折扣
 * @param {*} param0
 */
export const PriceItem = (props) => {
  const {
    type = 'row',
    align = 'left',
    currency,
    price: prePrice = 0,
    discount = 0,
    className,
    hasSelected,
    giftGoodsFlag,
    isShowBeforePrice = true,
    priceStyle = {},
    isShowCompareAtPrice = false,
    compareAtPrice: preCompareAtPrice = 0,
    extra = {},
    item = {},
  } = props;
  const { attachPropsPrice = 0, originalUnitPrice = 0, originalAttachUnitPrice = 0 } = item;
  const quantity = item?.quantity || 1; // quantity 可能存在为0 的情况，默认为1
  const compareAtPrice = preCompareAtPrice + attachPropsPrice;
  // 原价 = 原价 + 附加属性价格 (含税价)
  const originalPrice = originalUnitPrice + originalAttachUnitPrice;
  const price = originalPrice || prePrice + attachPropsPrice;
  const { showMultiCurrency = true } = useContext(MultiCurrencyContext);
  const { multiCurrencyInfo } = window?.global_data;
  const discountPrice = price - discount / quantity;
  const isShowDiscountPrice =
    !!discount && (hasSelected !== 0 || window?.location.pathname.indexOf('shopping-cart') === -1 || giftGoodsFlag); // 没有选择的商品不显示折扣价格

  // 是否积分兑换
  const isPointsExchange = extra?.points > 0;
  return (
    <PriceItemStyle className={cn(type, align, className)}>
      {isPointsExchange ? (
        <div className='points-detail'>
          <span className='points-num'>{extra?.points}</span>
          <span className='points-name'>
            {/* 积分单复数处理 */}
            {extra?.points === 1 ? extra.singleName : extra.complexName}
            {attachPropsPrice > 0 ? (
              ` + ${intlLiToYuan({
                currency,
                value: attachPropsPrice,
                multiCurrencyInfo: showMultiCurrency ? multiCurrencyInfo : null,
              })}`
            ) : (
              <></>
            )}
          </span>
        </div>
      ) : (
        <div className='price-detail skiptranslate'>
          {/* 现价 */}
          {isShowDiscountPrice && (
            <div className='goods-info-discount-money' style={priceStyle}>
              {intlLiToYuan({
                currency,
                value: discountPrice,
                multiCurrencyInfo: showMultiCurrency ? multiCurrencyInfo : null,
              })}
            </div>
          )}
          {/* 原价 */}
          {(isShowBeforePrice && isShowDiscountPrice) || !isShowDiscountPrice ? (
            <div className={cn('origin-price', { 'is-discount': isShowDiscountPrice })} style={priceStyle}>
              {intlLiToYuan({
                currency,
                value: price,
                multiCurrencyInfo: showMultiCurrency ? multiCurrencyInfo : null,
              })}
            </div>
          ) : (
            <></>
          )}
          {/* 原价 */}
          {!isShowDiscountPrice && isShowCompareAtPrice && compareAtPrice && compareAtPrice > price ? (
            <div className={cn({ 'origin-price': true, 'text-decoration': true })} style={priceStyle}>
              {intlLiToYuan({
                currency,
                value: compareAtPrice,
                multiCurrencyInfo: showMultiCurrency ? multiCurrencyInfo : null,
              })}
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
    </PriceItemStyle>
  );
};
// 具体折扣信息
export const DiscountInfo = ({
  align,
  discount,
  currency,
  discountCode = '',
  className = '',
  showPrice = true,
  isSetBgColor = false,
  isMemberDiscount = false,
}) => {
  return (
    <DiscountInfoStyle className={cn(className, align)}>
      {!!discount && (
        <span className={cn({ 'is-set-bgColor': isSetBgColor }, 'discount-code-detail')}>
          <svg className='discount-code-icon' aria-hidden='true'>
            <use xlinkHref='#iconicon_discount' />
          </svg>
          <span className='discount-code'>
            {isMemberDiscount ? '' : discountCode}
            {showPrice && (
              <span>
                {`(-${intlLiToYuan({
                  currency,
                  value: discount,
                })})`}
              </span>
            )}
          </span>
        </span>
      )}
    </DiscountInfoStyle>
  );
};
// 删除按钮
export const DeleteBtn = ({ onDelete, className, toWish = false, isSidebar }) => {
  const intl = useIntl();
  const { $fm } = useI18n(intl);
  const [isH5, setIsH5] = useState(false);

  useEffect(() => {
    if (window && window.innerWidth < 750) {
      setIsH5(true);
    }
  }, []);

  const showDeleteDialog = () => {
    if (isSidebar) {
      onDelete();
      return;
    }
    openDialog({
      dialogId: 'delete-dialog-id',
      title: (
        <DeleteBtnStyle>
          {' '}
          <div className='delete-title'>
            {toWish
              ? $fm('a6f4763588c24fffb7eb577abb7956a6', '确定将此商品移动到心愿单吗？')
              : $fm('896142a934d04e6a8ba5bb64e79e51d6', '确定删除此商品吗？')}
          </div>
        </DeleteBtnStyle>
      ),
      children: (
        <DeleteBtnStyle>
          <div className='delete-content-wrapper'>
            <span
              onClick={() => {
                closeDialog('delete-dialog-id');
              }}
            >
              {$fm('general.cancel', '取消')}
            </span>
            <span
              className='delete-text'
              onClick={() => {
                closeDialog('delete-dialog-id');
                onDelete();
              }}
            >
              {toWish ? $fm('general.confirm', '确认') : $fm('general.delete', '删除')}
            </span>
          </div>
        </DeleteBtnStyle>
      ),
      closeBtn: false,
      className: 'delete-confirm ',
    });
  };
  const Content = Popover.withPopover(function Content({ popover }) {
    return (
      <div>
        <DeleteBtnStyle>
          <div className='pop-content-wrapper'>
            <div>
              {toWish ? (
                <span>{$fm('a6f4763588c24fffb7eb577abb7956a6', '确定将此商品移动到心愿单吗？')}</span>
              ) : (
                <span>{$fm('896142a934d04e6a8ba5bb64e79e51d6', '确定删除此商品吗？')}</span>
              )}
            </div>
            <div className='action-wrapper'>
              <Button onClick={popover.close}>{$fm('general.cancel', '取消')}</Button>
              <Button type='primary' onClick={onDelete}>
                {$fm('general.confirm', '确认')}
              </Button>
            </div>
          </div>
        </DeleteBtnStyle>
      </div>
    );
  });
  return (
    <div>
      {!isH5 && !isSidebar && (
        <Popover className='zent-doc-popover' position={Popover.Position.BottomLeft} cushion={5}>
          <Popover.Trigger.Click>
            <DeleteBtnStyle className={cn(className, 'delete-btn')}>
              <svg className='icon delete-icon' aria-hidden='true' width='20' height='20'>
                <use xlinkHref={toWish ? '#iconzhuanyixinyuandan' : '#iconshanchu'} />
              </svg>
            </DeleteBtnStyle>
          </Popover.Trigger.Click>
          <Popover.Content>
            <Content />
          </Popover.Content>
        </Popover>
      )}
      {(isH5 || isSidebar) && (
        <DeleteBtnStyle
          className={cn(className, 'delete-btn')}
          onClick={() => {
            showDeleteDialog();
          }}
        >
          <svg className='icon delete-icon' aria-hidden='true' width='20' height='20'>
            <use xlinkHref={toWish ? '#iconzhuanyixinyuandan' : '#iconshanchu'} />
          </svg>
        </DeleteBtnStyle>
      )}
    </div>
  );
};

export const GoodsTag = ({ isGroupBuying }) => {
  const intl = useIntl();
  const { $fm } = useI18n(intl);

  return (
    <>{isGroupBuying && <TagStyle className='group-tag'>{$fm('96a88360cb79486cb9ebed4bf2b801c2', '拼团')}</TagStyle>}</>
  );
};
