import { useState, useEffect } from 'react';
import goodsApi from 'api/goods';
import shopApi from 'api/shop';

export const useGoodsLocation = (props) => {
  const { goodsId, isECardGoods } = props;
  const [isPickupGoods, setIsPickupGoods] = useState(false);
  const [pickupLocation, setPickupLocation] = useState({});
  const [locationList, setLocationList] = useState([]);

  useEffect(() => {
    if (!goodsId) return;
    if (isECardGoods) {
      shopApi.bulkGetLocations().then((res) => {
        setLocationList(res);
      });
    } else {
      getIsPickupGoods();
    }
  }, [goodsId]);

  const getIsPickupGoods = () => {
    goodsApi.isPickupGoods({ goodsId }).then((res) => {
      if (res === true) {
        setIsPickupGoods(res);
        // getPickupLocationOverview();
      }
    });
  };

  // 传统网页默认取列表第一个
  const getPickupLocationOverview = () => {
    goodsApi.getPickupLocationOverview({  goodsId }).then((res) => {
      setPickupLocation(res || {});
    });
  };

  return {
    isPickupGoods,
    pickupLocation,
    locationList,
  };
};
