import styled from 'styled-components';
import { getSizeVw } from '../../../../../style/fn';

export default styled.div`
  .header-announcement {
    line-height: 24px;
    padding: 12px 0;
    background: #1a1a1b;
    text-align: center;
  }
  .announcement_link {
    color: #fff;
  }
  &.cn-full_screen {
    .sub-header-container {
      padding: 0 ${(props) => props.theme.getSpace([16, 80])};
    }
  }
  .sub-header-container {
    position: relative;
    box-sizing: border-box;
  }
  &.cn-fix_1280,
  &.cn-fix_1440,
  &.cn-fix_960 {
    .menu-list {
      margin-left: 0px !important;
    }
    .cn-left {
      .cn-sub-left .first-level-header-sub-menu {
        padding-left: 30px !important;
        justify-content: left;
        .first-level-menu,
        .header-sub-minu-item {
          text-align: left !important;
        }
      }
    }
    .cn-right {
      justify-content: flex-end;
      .menu-list {
        margin-right: 80px !important;
      }
    }
  }
  .menu-list {
    position: relative;
  }
  .menu-list-wrapper {
    flex: 1;
    position: relative;
    padding: 0 24px;
    box-sizing: border-box;
    height: 100%;
  }
  .wrapper {
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    height: 100%;
    &.cn-left {
      justify-content: left;
      align-items: left;
      .menu-list {
        /* margin-left: -${getSizeVw(32)}; */
      }
      .cn-sub-left .first-level-header-sub-menu {
        padding-left: 80px;
        justify-content: left;
        .first-level-menu,
        .header-sub-minu-item {
          text-align: left !important;
        }
      }
    }
    &.cn-center {
      .pc-header-logo {
        margin-right: 8px !important;
      }
    }
    &.cn-right {
      justify-content: flex-end;
      .menu-list {
        /* margin-right: -${getSizeVw(32)}; */
        .first-menu-item-wrapper:last-child .first-menu-item {
          padding-right: 0;
        }
      }
    }
  }

  .menu-list {
    display: flex;
    flex-wrap: nowrap;
    padding: 8px 0;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    justify-content: ${(props) => (props.showRightScrollIcon ? 'flex-start' : props.mainMenuAlign)};
    overflow: -moz-scrollbars-none;
    /*火狐下隐藏滚动条*/
    overflow: -moz-scrollbars-none;
    align-items: center;
    /*谷歌下隐藏滚动条*/
    &::-webkit-scrollbar {
      display: none;
      height: 0;
    }
    height: 100%;
    box-sizing: border-box;
  }
  .scroll-icon {
    position: absolute;
    z-index: 2;
  }
  .scroll-icon-right {
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .scroll-icon-left {
    left: 0;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
  }
  .first-menu-item-wrapper {
    height: 100%;
    display: flex;
    flex-shrink: 0;
    align-items: center;
  }
  .pc-sub-menu {
    max-height: 0px;
    opacity: 0;
    .header-sub-menu-container {
      max-height: 0px;
      transition: max-height 0.2s;
    }
    &.active {
      opacity: 1;
      .header-sub-menu-container {
        max-height: 900px;
      }
      .first-level-header-sub-menu {
        padding: 40px 80px;
      }
      .adapt-content-width {
        padding: ${(props) => props.theme.getSpace(props.theme.vContainer)} !important;
      }
    }
  }
  .first-menu-item {
    ${(props) => props.theme.getHeaderSize([14, 14])};
    font-family: var(--font-stack-header);
    font-style: var(--font-style-header);
    font-weight: var(--font-weight-header);
    color: ${(props) => props.gColor};

    cursor: pointer;
    text-align: center;
    padding: 0 ${(props) => `${props.pcMenuPadding}px`}; // 因为写了pc header 和 app header 所以写死
    cursor: pointer;
    margin-top: 3px;
    .first-menu-item-inner {
      display: inline-block;
      border-bottom: 3px solid transparent;
      &:hover {
        border-bottom: 3px solid ${(props) => props.gColor};
      }
    }
    a {
      color: ${(props) => props.gColor};
    }
  }

  .home {
    font-weight: 500;
    color: var(--color_text);
    font-size: var(--base-header-font-16-14);
    font-family: var(--font-stack-header);
    font-style: var(--font-style-header);
    font-weight: var(--font-weight-header);
  }

  .mask {
    left: -0;
    top: 0px;
    z-index: 99;
    width: 100%;
    height: 200%;
    position: absolute;
    background: rgba(0, 0, 0, 0.1);
  }

  .header-center-logo-container .header-operate-container {
    position: absolute;
    transform: translateY(-50%);
    right: 80px;
    top: 50%;
  }

  .header-center-logo {
    position: relative;
    text-align: center;
  }
  .header-center-logo-container {
    padding-top: 32px;
  }
  .cn-sub-center .first-level-header-sub-menu {
    justify-content: center;
  }
  .cn-sub-left .first-level-header-sub-menu {
    justify-content: left;
    .first-level-menu,
    .header-sub-minu-item {
      text-align: left !important;
    }
  }
  .first-menu-item-inner {
    position: relative;
  }
  .header-sub-minu-item a {
    position: relative;
  }
  .header-badge {
    position: absolute;
    top: -10px;
    right: 0;
    transform: translateX(100%);
    width: max-content;
    pointer-events: none;
  }
  .first-level-header-sub-menu {
    overflow: scroll;
    box-shadow: 0 4px 8px ${(props) => props.theme.hexToRGBA(props.defaultTextIconColor, 0.04)};
    &::-webkit-scrollbar {
      display: none;
    }
    &.empty-div {
      height: 0;
    }
  }
`;
