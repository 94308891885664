import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex: 2;
  align-items: flex-start;
  position: relative;
  .info {
    /* height: 120px;此处是要如何。。
        display: flex;
        flex-direction: column;
        justify-content: space-between; */
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .wrap {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    .warn-tip {
      color: #ec5b56;
      font-size: 12px;
    }
  }
  .select-wrapper {
    margin-right: 16px;
    align-self: center;
    &.select-wrapper-status-selected svg {
      fill: var(--color_button);
    }
    &.select-wrapper-status-no-selected svg {
      fill: rgba(var(--color_body_text_rgb), 0.2);
    }
  }
  .delete-wrapper {
    display: flex;
  }
  .mt16 {
    margin-top: 16px;
  }
  .mt10 {
    margin-top: 10px;
  }
  .flex-not1 {
    flex: unset;
  }
  .delete-wrapper {
    display: flex;
    width: 56px;
    gap: 8px;
    /* justify-content: space-between; */
  }
`;

export const TagStyle = styled.div`
  margin-top: 10px;
  color: var(--color_sale_text);
  background: rgba(var(--color_sale_text_rgb), 0.1);
  padding: 2px;
  font-size: 12px;
  display: inline-block;
  border-radius: 1px;
`;
