import React, { useMemo, isValidElement } from 'react';
import cn from 'classnames';
import Style from './style';
import { formateTimeByTimeZone } from 'utils/datetime';
import { Icon } from 'components/base';

enum AvailablePeriodType {
  AllDay = 'all_day',
  SpecifyPeriod = 'specify_period',
}
enum PeriodType {
  Permanent = 'permanent',
  SpecifyPeriod = 'specify_period',
}
enum PerUsableQtyLimitType {
  NoLimit = 0,
  Limit = -1,
}
const Format = 'YYYY-MM-DD HH:mm';
const UseOfRule = props => {
  const {
    $fm,
    ecardExtra = {},
  } = props;
  const ruleList = useMemo(() => {
    const {
      availablePeriodType,
      expiredAutoRefund,
      instructions = '',
      perUsableQtyLimit,
      restrictions = '',
      availablePeriods = [],
      usablePeriod = {},
    } = ecardExtra;
    const {
      type,
      usableEndAt,
      usableStartAt,
    } = usablePeriod;
    const instructionsDesc = instructions.replace(/\n/g, '<br />'); // 富文本对文本域的\n换行不生效，需要二次处理
    const restrictionsDesc = restrictions.replace(/\n/g, '<br />'); // 富文本对文本域的\n换行不生效，需要二次处理

    return [{
      title: $fm("70382e433521489593a7c25b8e5548fa", "有效期"),
      desc: type === 1 ? $fm("long.time.effective", "长期有效") : `${formateTimeByTimeZone(usableStartAt, Format)} ${$fm('commonl_to')} ${formateTimeByTimeZone(usableEndAt, Format)}`,
      icon: 'iconyouxiaoqi',
      id: 'periodType',
      show: true
    }, {
      title: $fm("e66f06c860f440b0a23d7b4df6d54712", "可用时间"),
      desc: availablePeriodType === AvailablePeriodType.AllDay ? $fm("07ef854379744d6b936581089ee628cb", "全天可用") : `${$fm('6a86d5d1efab43ccb35507400985fc21')}${availablePeriods.map(a => `${a.startAt}-${a.endAt}`).join('、')}`,
      icon: 'iconkeyongshijian',
      id: 'availablePeriodType',
      show: true
    }, {
      title: $fm("ad9121ad068b4d1396a737cfa795fc09", "不可用时间"),
      desc: restrictionsDesc,
      icon: 'iconbukeyongshijian',
      id: 'restrictionsDesc',
      show: !!restrictions
    }, {
      title: $fm("c77b20c0c94f4573b05cf898944af3aa", "单次可用数量"),
      desc: perUsableQtyLimit === PerUsableQtyLimitType.NoLimit ? $fm("38707116dcf5410b8a8db0846335d49c", "无限制") : $fm('43d392b5f3364d97822833a9f2ae642a', '{num}张', { num: perUsableQtyLimit }),
      icon: 'iconyouhuiquan',
      id: 'perUsableQtyLimit',
      show: true
    }, {
      title: $fm("73e46bb799d44ed7af17068e2948e9b2", "使用规则"),
      desc: instructionsDesc,
      icon: 'icondingdan',
      id: 'instructionsDesc',
      show: !!instructions
    }, {
      title: $fm("586a085883b845d6b3eed2ddb0907c9d", "支持过期自动退款"),
      desc: $fm("09ad86d3f0984acebea95c5f047f6b28", "超过有效期后，如未使用会原路退款"),
      icon: 'iconguoqituikuan',
      id: 'expiredAutoRefund',
      show: expiredAutoRefund === 1
    }];
  }, [ecardExtra]);
  
  return (
    <Style>
      <ul className='rule-item-list'>
        {ruleList.filter(r => r.show).map((item, index) => {
          const itemId = item?.id;
          return (
            <li key={itemId} className='rule-item'>
              <Icon name={item.icon} size={16} />
              <div className={cn('rule-content')}>
                <span className='title'>{item.title}</span>
                {
                  ['restrictionsDesc', 'instructionsDesc'].includes(itemId) ?
                  <span className='desc' dangerouslySetInnerHTML={{ __html: item.desc }}></span> :
                  <span className='desc'>{item.desc}</span>
                }
              </div>
            </li>
          )
        })}
      </ul>
    </Style>
  );
};
export default UseOfRule;