/** 店铺的商业化版本 */
export enum PackageCode {
  TRIAL = 'trial', // 试用版
  BASIC = 'basic', // 基础班
  BASIC_2023 = 'basic_2023', // 2023 新版基础版
  STANDARD = 'standard', // 专业版
  PREMIUM = 'premium', // 高级版
  PREMIUM_2023 = 'premium_2023', // 2023 新版高级版
  ULTIMATE = 'ultimate', // 旗舰版
  ENTERPRISE = 'enterprise', // 企业版
  ENTERPRISE_2023 = 'enterprise_2023', // 2023 新版企业版
  PLATINUM = 'platinum', // 铂金版
  HUNGRY_PANDA = 'hungry_panda', // 熊猫外卖版本
}

/** 店铺的商业化版本，以下必须是按顺序从小到大排的 */
export const PackageCodeSorted = [
  PackageCode.BASIC,
  PackageCode.BASIC_2023,
  PackageCode.STANDARD,
  PackageCode.PREMIUM,
  PackageCode.PREMIUM_2023,
  PackageCode.ULTIMATE,
  PackageCode.PLATINUM,
  PackageCode.ENTERPRISE,
  PackageCode.ENTERPRISE_2023,
  PackageCode.TRIAL, // 注意！！！，目前试用版本有任何功能，所以权限最大把他放最后，别换位置
];
