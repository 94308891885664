import React, { useMemo } from 'react';
import { Style } from './style';
import cn from 'classnames';
import { useWish } from './use-wish';
import { GOODS_FEATURE_TYPE } from 'client/const';

const SimpleGoodsWishBtn = ({
  settings = {},
  data = {},
  initData = {},
  className = '',
  showAddCart = false,
  boxWidth = 'fix_1280',
  showWish = true,
  onAddCartClick,
  addCartDisabled = false,
}) => {
  const {
    text_display: textDisplay = 'superimposed',
    text_position: textPosition = 'left top',
    container_width: containerWidth = 1280,
    grid,
  } = settings;

  const { wishList, goodsId } = data;

  const { wishStatus, wishActionLoading, handleChangeWishStatus } = useWish({
    data,
    kdtId: initData?.kdtId,
    customerId: initData?.userInfo?.customerId,
    goodsId,
    wishList,
  });

  let newBoxWidth = 0;
  switch (boxWidth) {
    case 'full_width':
      newBoxWidth = document.body.clientWidth;
      break;
    case 'fix_1440':
      newBoxWidth = 1440;
      break;
    case 'fix_960':
      newBoxWidth = 960;
      break;
    case 'fix_1280':
      newBoxWidth = 1280;
      break;
    case 'margin_80':
      newBoxWidth = document.body.clientWidth - 160;
      break;
  }

  return (
    <Style
      className={className}
      textDisplay={textDisplay}
      textPosition={textPosition}
      containerWidth={containerWidth || newBoxWidth}
      grid={grid}
    >
      {showWish && ![GOODS_FEATURE_TYPE.newService].includes(data?.goodsFeatureType) && (
        <span className='add-wish-btn' onClick={handleChangeWishStatus}>
          {wishStatus ? (
            <svg className='wish-icon wish-icon-active' aria-hidden='true' fill='#323233'>
              <use xlinkHref='#iconic-shoucang1' />
            </svg>
          ) : (
            <svg className='wish-icon' aria-hidden='true'>
              <use xlinkHref='#iconic-shoucang2' />
            </svg>
          )}
        </span>
      )}
      {showAddCart && ![GOODS_FEATURE_TYPE.eCard].includes(data?.goodsFeatureType) && (
        <span
          className={cn('add-cart-btn', { 'add-cart-disabled': addCartDisabled })}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            if (!addCartDisabled) onAddCartClick();
          }}
        >
          <svg className='cart-icon' aria-hidden='true' fill='#323233'>
            <use xlinkHref='#icondaohanglantubiao_gouwuche' />
          </svg>
        </span>
      )}
    </Style>
  );
};

export default SimpleGoodsWishBtn;
