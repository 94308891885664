import React, { useState, useEffect } from 'react';
import Style, { GlobalStyle } from './style';

import { useIntl } from 'react-intl';

const NotFindPage = () => {
  const intl = useIntl();

  const handleErrorTest = () => {
    console.log('handle error');
    console.log(intl.a());
  };

  return (
    <Style>
      <GlobalStyle />
      <div className='not-find-page'>
        <svg className='not-find-icon' width={80} height={80}>
          <use xlinkHref='#iconkongzhuangtai-wangluoxinhao' />
        </svg>
        <div className='not-find-title'>
          {intl.formatMessage({
            defaultMessage: '无法访问此页面',
            id: '098008ffd42b4132b47bfddfcb83aa82',
          })}
        </div>
        <div className='not-find-desc'>
          {intl.formatMessage({
            defaultMessage: '请检查输入的网址是否正确，或稍后重试',
            id: '60fd968067fa48d6bf81818794db746b',
          })}
        </div>
        <a className='not-find-action' href='/'>
          {intl.formatMessage({
            defaultMessage: '回到首页',
            id: '11858eaafcd444069a3f15c9a4510f8b',
          })}
          <svg width={12} height={12}>
            <use xlinkHref='#iconic-jiantouyou' />
          </svg>
        </a>
      </div>
      <div style={{ display: 'none' }} onClick={handleErrorTest}>handle error</div>
    </Style>
  );
};

// function NotFindPage({ staticContext }) {
//     if (staticContext) {
//         staticContext.code = 404;
//     }
//     return <div>404页面</div>;
// }

export default NotFindPage;
