import React, { useContext } from 'react';
import { GoodsTitle, MultipleSpecifications, ImageBox, PriceItem, DiscountInfo, GoodsTag } from 'cpn/goods-item';
import { getUnitDiscount } from 'helper/discount';
import { useIntl } from 'react-intl';
import Style from './style';
import cn from 'classnames';
import MultiCurrencyContext from '../../../context/multi-currency';
import { useI18n } from 'hook/intl';

export const StandardGoodsItem = (props) => {
  const intl = useIntl();
  const { $fm } = useI18n(intl);

  const {
    item,
    currency,
    discountInfo = {},
    showDiscount = true,
    isSetBgColor,
    isOrderGoods = false,
    isGroupBuying,
    priceAlignType,
    invalidReason = null,
    showTotal = false,
  } = props;

  const {
    imageUrl = '',
    quantity = 1,
    title = '',
    handle = '',
    sku = [],
    optionValues = [],
    price = 0,
    discountAmount = 0,
    simpleMemberGoodsVariantInfo,
    goodsLevelPromotions,
    totalDiscount = 0,
    unitPrice = 0,
    extra,
    attachProps = [],
    attachPropsPrice = 0,
    totalPrice = 0,
  } = item || {};

  // 是否是会员的折扣
  const isMemberDiscount = goodsLevelPromotions?.[0]?.type === 'member_price';

  let unitDiscount = 0;
  if (goodsLevelPromotions?.[0]?.type) {
    unitDiscount = goodsLevelPromotions?.[0].discountAmount;
  }

  const { discountCode } = discountInfo || {};
  const { showMultiCurrency = true } = useContext(MultiCurrencyContext);

  return (
    <div className={cn('goods-item-one')}>
      <Style>
        {item.isInvalid === 1 && (
          <div className='invalid-text'>
            <span>{$fm('8693ae24e81c460881ac3d6777fce5bc', '失效')}</span>
          </div>
        )}
        <ImageBox
          imageUrl={imageUrl}
          className={cn({ 'invalid-opacity': item.isInvalid === 1 })}
          style={{ width: '102px', height: '102px', minWidth: '102px' }}
          handle={handle || title}
        />
        <div className={cn('info', { 'info-wrapper': isOrderGoods }, { 'invalid-opacity': item.isInvalid === 1 })}>
          <div data-type={goodsLevelPromotions?.[0]?.type}>
            <GoodsTitle title={title} />
            <MultipleSpecifications
              optionTitles={sku}
              optionValues={optionValues}
              attachProps={attachProps}
              className='mt10'
            />
            <GoodsTag isGroupBuying={isGroupBuying} />
          </div>

          {!showTotal && (
            <>
              <div className={cn({ 'line-row': isOrderGoods })}>
                <p className='count mt10'>x{quantity}</p>
                <PriceItem
                  price={unitPrice}
                  discount={unitDiscount}
                  currency={currency}
                  className='mt10'
                  type={priceAlignType}
                  showMultiCurrency={showMultiCurrency}
                  extra={extra}
                  item={item}
                />
              </div>

              {!!(discountAmount || totalDiscount) && showDiscount && isMemberDiscount && (
                <div className='discount-info-wrapper'>
                  <DiscountInfo
                    discount={discountAmount || totalDiscount}
                    currency={currency}
                    discountCode={discountCode}
                    className='mt10'
                    isSetBgColor={isSetBgColor}
                    isMemberDiscount={isMemberDiscount}
                  />
                </div>
              )}
            </>
          )}
        </div>

        {showTotal && (
          <div className='total-price-wrapper'>
            <p className='count'>x{quantity}</p>
            <PriceItem
              price={totalPrice}
              currency={currency}
              className='mt10'
              type={priceAlignType}
              showMultiCurrency={showMultiCurrency}
            />
          </div>
        )}
      </Style>
      {invalidReason}
    </div>
  );
};
