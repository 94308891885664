/**
 * 商品详情页
 */
import React, { useState } from 'react';
import { useThemeContext } from 'hook/global-theme';
import { GoodsLayout } from 'components/page/goods-detail/goods-layout/index';
import { useReview } from 'components/page/goods-detail/goods-layout/hooks/index';
import { ProductView } from '../product-view';
import { useCart } from 'hook/cart/cart';
import { useWish } from 'hook/goods/wish';
import { getPageDecorationData } from 'helper/goods';

const ProductTemplate = (props) => {
  const { sectionSetting = {}, initialData = {}, elemClass } = props;
  const { isMobile } = useThemeContext(); // 是否是手机端
  const { goods: serverData = {}, shopSetting = {}, shopInfo = {}, userInfo, kdtId } = initialData;
  const customerId = userInfo?.customerId || 0;
  const domain = shopInfo.primaryDomain;
  const { currency, language } = shopSetting;
  const [goodsDetail, setGoodDetail] = useState(serverData);
  const {
    baseConfigInfo = {},
    baseImageStyle = '',
    detailConfigInfo = {},
    globalSetting = {},
    socialShareData = {},
  } = getPageDecorationData(sectionSetting, isMobile); // 设置页面装修数据

  /** 心愿单 */
  const wishHook = useWish({
    goodsDetail,
    initialData: props.initialData,
    customerId,
  });

  /** 购物 */
  const cartHook = useCart({
    goodsDetail,
    history: props.history,
  });

  /** 评价 */
  const reviewHook = useReview(goodsDetail);

  const baseProps = {
    /** 基础信息 */
    kdtId,
    currency,
    language,
    /** 装修 */
    baseConfigInfo,
    detailConfigInfo,
    socialShareData,
    globalSetting,
    baseImageStyle,
    elemClass,
    /** 数据 */
    goodsDetail,
    userInfo,
    isMobile,
    cartHook,
    reviewHook,
    history: props.history,
  };

  const baseContainerData = {
    ...baseProps,
    history: props.history,
    wishHook,
    goodsId: goodsDetail?.goodsId,
    kdtId,
    domain,
  };
  return <GoodsLayout {...baseProps} baseContainer={<ProductView {...baseContainerData} />} />;
};

export default ProductTemplate;
