/* eslint-disable @typescript-eslint/no-empty-function */
import superagent from 'superagent';
import LocalStorage from 'utils/local-storage';
import { getQueryString } from 'utils';
import axios from 'axios';
import { Notify } from 'zent';

export const getImageInfoByDom = (imgUrl) => {
  return new Promise((resolve, reject) => {
    let set = 0;
    const img = new Image();

    img.src = imgUrl;

    const check = () => {
      if (img.width > 0 || img.height > 0) {
        clearInterval(set);
        resolve({
          width: img.width,
          height: img.height,
        });
      }
    };
    set = setInterval(check, 40);

    img.onload = () => {
      resolve({
        width: img.width,
        height: img.height,
      });
    };

    img.onerror = () => {
      reject();
    };
  });
};

const getImageInfoUrl = (originUrl) => {
  if (originUrl?.indexOf('?imageInfo') !== -1) {
    return originUrl;
  }
  if (originUrl?.indexOf('?') !== -1) {
    return `${originUrl}&imageInfo`;
  } else {
    return `${originUrl}?imageInfo`;
  }
};

const getImageWAndH = (url) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    // 图片路径赋值
    image.src = url;

    image.onload = function () {
      const { width } = this;
      const { height } = this;
      resolve({ width, height });
    };
    image.onerror = function (err) {
      reject(err);
    };
  });
};

// const getWhWidthUrl
/**
 * 根据地址获取图片宽高
 * @param {*} url
 */
export const getImageInfo = (url) => {
  if (!url) {
    return Promise.reject();
  }

  /** 从图片地址上获取图片宽高 */
  const { height, width } = getQueryString(url) || {};
  if (height && width) {
    return Promise.resolve({
      height: Number(height),
      width: Number(width),
    });
  }

  /** 从缓存中获取图片宽高  */
  const cacheInfo = LocalStorage.get(url);
  if (cacheInfo?.height && cacheInfo?.width) {
    return Promise.resolve(cacheInfo);
  }
  /** 非cdn的图片通过节点获取图片宽高 */
  if (url.indexOf('yzcdn.cn') === -1) {
    return getImageInfoByDom(url);
  }
  /** cdn图片从七牛接口读取  */
  const completeUrl = getImageInfoUrl(url);
  return superagent.get(completeUrl).then(async (res) => {
    const imageInfo = res?.body || {};
    if (!imageInfo?.height) {
      const imageInfo2 = await getImageWAndH(url);
      LocalStorage.set(url, imageInfo2, 720);
      return imageInfo2;
    }
    LocalStorage.set(url, imageInfo, 720);
    return imageInfo;
  });
};

/**
 * 获取视频比例
 */
export const getImageInfoPer = async (url) => {
  try {
    const imageInfo = await getImageInfo(url);
    const { width, height } = imageInfo;
    const percent = (height / width) * 100;
    return `${percent}%`;
  } catch (error) {
    console.log(error);
  }
};

/**
 * 获取YouTube视频地址
 */
export const getYouTubeVideoSrc = ({ url, autoPlay = 0 }) => {
  if (!url) return '';
  const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const videoId = url.match(regExp)?.[2];
  return `https://www.youtube.com/embed/${videoId}?autoPlay=1&enablejsapi=1&controls=0&enablejsapi=1&playsinline=1`;
};

let abortControl = null;
/** 上传七牛- */
export const qiniuImageUpload = async ({
  file,
  token,
  categoryId,
  onProgress,
  onSuccess = () => {},
  onError = () => {},
}) => {
  try {
    const fd = new window.FormData();
    fd.append('file', file);
    fd.append('token', token);

    if (categoryId) {
      fd.append('x:categoryId', categoryId);
    }

    abortControl = new AbortController();
    axios({
      method: 'POST',
      url: '//upload.qbox.me/',
      data: fd,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        const loaded = progressEvent.loaded || 0, total = progressEvent.total || 0;
        onProgress && onProgress(Math.floor(Math.ceil((loaded / total) * 100)));
      }
    }).then(fileUploadResult => {
      if (fileUploadResult?.data?.data) {
        const {
          attachment_full_url: imgUrl,
          attachment_id: imgId,
          attachment_title: title,
          width,
          height,
        } = fileUploadResult?.data?.data || {};
        onSuccess({
          imgId,
          url: imgUrl,
          type: 'image',
          title,
          width,
          height,
        });
      } else {
        Notify.error(fileUploadResult?.data?.msg || 'Uploading failed');
        onError(fileUploadResult?.data);
      }
    }).catch(err => {
      // 手动取消不提示
      if (abortControl.signal.aborted) return;
      Notify.error(err?.response?.data?.error || 'Uploading failed');
      onError('Uploading failed');
    })
  } catch (error) {
    onError('Uploading failed');
  }
};


export const cancelUpload = () => {
  if (abortControl) {
    abortControl.abort();
  }
};

export const downloadImage = async ({ fileName, imgUrl }) => {
  const imageToBase64 = () => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.setAttribute('crossOrigin', 'Anonymous');
      img.src = imgUrl;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, img.width, img.height);
        const dataURL = canvas.toDataURL('image/jpeg');
        resolve(dataURL);
      };
      img.onerror = () => {
        reject(new Error('load picture error'));
      };
    });
  };
  let downloadUrl;
  if (imgUrl.includes('http')) {
    downloadUrl = await imageToBase64();
  } else {
    downloadUrl = imgUrl;
  }
  const element = document.createElement('a');
  document.body.appendChild(element);
  element.href = downloadUrl;
  element.download = fileName;
  element.click();
  document.body.removeChild(element);
};
