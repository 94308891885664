import styled from 'styled-components';

export default styled.div`
  .more-third-btn-container {
    .third-btn-entry {
      position: relative;
      align-items: center;
      font-size: 16px;
      padding-top: 16px;
      cursor: pointer;
      .entry-toggle-icon {
        fill: var(--color_body_text);
      }
      .is-expand {
        transform: rotate(180deg);
      }
    }
    .is-h5 {
      display: flex;
      justify-content: center;
    }
    .is-pc {
      display: inline-flex;
      justify-content: flex-start;
    }
  }
  .third-btn-container {
    padding: 4px;
    background: #fff;
    .third-btn-item {
      padding: 12px;
      display: flex;
      align-items: center;
      cursor: pointer;
      width: 272px;
      &:hover {
        background: rgba(48, 79, 254, 0.1);
      }
      .third-btn-logo {
        height: 24px;
        width: 24px;
      }
      .third-btn-name {
        margin-left: 16px;
      }
    }
  }
  .third-buy-link {
    background: var(--color_button);
    border-radius: var(--border_radius_btn);
    &.custom {
      .text {
        color: var(--color_button_text);
      }
    }
  }
  .is-h5 {
    width: 100%;
  }
  .mask {
    background: #ffffff;
    box-shadow: 0px 0px 16px rgba(51, 51, 51, 0.08);
    border-radius: 2px;
  }
  .mask-container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: flex-end;
    background: rgba(0, 0, 0, 0.3);
    padding: 0 !important;
    .third-btn-container {
      width: 100%;
      padding-bottom: 34px;
      .third-btn-header {
        display: flex;
        height: 64px;
        align-items: center;
        justify-content: space-between;
        padding: 0 24px;
        .title {
          font-size: 20px;
          color: #15161b;
          font-weight: 600;
        }
      }
      .third-btn-item {
        width: 100%;
        padding: 0 24px;
        height: 64px;
        border-top: 0.5px solid #ebedf0;
        box-sizing: border-box;
      }
    }
  }
`;
