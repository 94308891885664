import React, { useEffect, useState } from 'react';
import Style from './style';
import ThirdBtnList from './third-btn-list';
import cn from 'classnames';

const GoodsThirdButtonList = (props) => {
  const { thirdPlatformBtnList } = props;
  const isH5 = window.innerWidth < 450;

  // 跳转第三方平台
  const onVisitThirdLink = (url) => {
    const redirectUrl = url.indexOf('http') > -1 ? url : `http://${url}`;
    window.open(redirectUrl);
  };

  const defaultThirdBtn = thirdPlatformBtnList[0];

  return (
    <Style>
      {defaultThirdBtn && (
        <div className='goods-buy-button'>
          <div
            className={cn('third-buy-link', { 'is-h5': isH5, custom: !defaultThirdBtn.buttonStyle })}
            style={defaultThirdBtn.buttonStyle}
            onClick={() => {
              onVisitThirdLink(defaultThirdBtn?.url);
            }}
          >
            {defaultThirdBtn?.cImgUrl && (
              <div className='img-container'>
                <img src={defaultThirdBtn?.cImgUrl} alt='Purchases from third-party platforms' />
              </div>
            )}

            <span className='text'>{defaultThirdBtn?.buttonName}</span>
          </div>
          {thirdPlatformBtnList?.length > 1 && <ThirdBtnList thirdPlatformBtnList={thirdPlatformBtnList} />}
        </div>
      )}
    </Style>
  );
};

export default GoodsThirdButtonList;
