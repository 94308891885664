import BuyNow from '../buy-now-v2';
import SkuStyle from './style.js';
import { createPortal } from 'react-dom';
import React, { useState, useRef, useEffect } from 'react';
import { isUndefined } from 'lodash';

const SkuPageV2 = ({
  goodsId,
  setSkuPageShow,
  preVariantId,
  updateCartList,
  quantity,
  onCartSkuChange,
  selectedAttachProps = [],
  domClassName,
  preItemKey = '',
}) => {
  const isPC =
    window &&
    (domClassName ? document.querySelector(`.${domClassName}`)?.offsetWidth : document.body.clientWidth) > 750;
  const containerDisplay = isPC ? 'block' : 'flex';
  const skuSelectRef = useRef({});
  const { kdtId, shopSetting = {} } = window?.global_data;
  const skuElement = (
    <SkuStyle>
      <div
        style={{
          display: domClassName ? 'block' : containerDisplay,
        }}
        className='add-shopping-car-container'
        onClick={(e) => e.stopPropagation()}
      >
        <BuyNow
          skuSelectRef={skuSelectRef}
          goodsId={goodsId}
          kdtId={kdtId}
          currency={shopSetting?.currency}
          triggerSkuBlock={setSkuPageShow}
          preVariantId={preVariantId}
          preItemKey={preItemKey}
          preNum={quantity}
          updateCartList={updateCartList}
          isPC={isPC}
          onCartSkuChange={onCartSkuChange}
          selectedAttachProps={selectedAttachProps}
        />
      </div>
    </SkuStyle>
  );
  if (isPC) {
    return skuElement;
  }
  if (domClassName) {
    return createPortal(skuElement, document.querySelector(`.${domClassName}`));
  }
  return createPortal(skuElement, document.getElementById('root'));
};

export default SkuPageV2;
