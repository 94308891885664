import { Style } from './style';
import { DiscountWrapper } from 'cpn/discount/discount-wrapper';
import { CartItem } from '../cart-goods-list-item';
import React, { useMemo } from 'react';
import CartGiftList from '../../cart-gift-list';

import { DiscountType, ApplyDiscountStatus } from 'const/discount';

const CartActivityCard = ({
  discountInfos,
  lineItems,
  currency,
  language,
  isDrawer = false,
  isExpiredGoodsStatus,
  itemProps,
  giftProps,
  domClassName,
}) => {
  const bxgyActivity = discountInfos.filter((item) => item.discountType === DiscountType.Bxgy)?.[0] || {};
  const { promotionId, discountGets = {} } = bxgyActivity;
  const { discountQuantity } = discountGets;

  const giftGoods = lineItems.filter((item) => item?.giftGoodsFlag);

  const isShowGiftList = useMemo(() => {
    return !!bxgyActivity?.applyDiscountStatus && bxgyActivity?.applyDiscountStatus !== ApplyDiscountStatus.NotFit;
  }, [bxgyActivity?.applyDiscountStatus]);

  return (
    <Style className='cart-activity-card'>
      {discountInfos.map((discountInfoItem) => {
        return (
          <DiscountWrapper
            discountInfo={discountInfoItem}
            discountGoods={lineItems}
            currency={currency}
            language={language}
            isExpiredGoodsStatus={isExpiredGoodsStatus}
          />
        );
      })}
      {lineItems.map((item, index) => {
        if (item.giftGoodsFlag) return null;
        return (
          <CartItem
            item={item}
            index={index}
            // key={`${item?.variantId}-${item?.goodsId}-${item.quantity}-${item.giftGoodsFlag}`}
            key={item.itemKey}
            currency={currency}
            isExpiredGoodsStatus={isExpiredGoodsStatus}
            {...itemProps}
            domClassName={domClassName}
          />
        );
      })}
      {promotionId && isShowGiftList && (
        <CartGiftList
          isDrawer={isDrawer}
          itemProps={itemProps}
          {...giftProps}
          promotionId={promotionId}
          currency={currency}
          discountQuantity={discountQuantity}
          giftGoods={giftGoods}
          domClassName={domClassName}
        />
      )}
    </Style>
  );
};

export default CartActivityCard;
