import React, { useEffect, useState } from 'react';
import SkuSelect from '../sku-select';
import Style from './style';
import goodsApi from '../../../api/goods';
import { withRouter } from 'react-router-dom';
import { ProductMedia } from 'components/page/goods-detail/image-container/components/product-media';
import { getMedia } from 'helper/goods';
import { HtGoodsDetailPrice, HtGoodsSkuPrice } from 'cpn/goods-price';
import cn from 'classnames';
import { ContextGoodDetail } from '../../context/context-good-detail';
import { getTotalAttachPropsPrice } from 'cpn/sku-list/additional-items/utils';

const BuyNow = (props) => {
  const {
    kdtId,
    goodsId,
    triggerSkuBlock,
    setGoodsMultipleSkuStatus = () => { },
    skuSelectRef,
    history,
    enablePaymentButton,
    setSkuPageCompleteStatus,
    currency,
    preVariantId,
    preItemKey,
    preNum,
    onCartSkuChange,
    isPC,
    selectedAttachProps = [],
  } = props;
  const [goodsDetail, setGoodsDetail] = useState(null);
  const [currentMedia, setCurrentMedia] = useState([]);
  const [cart, setCart] = useState({});
  const [goodCover, setGoodCover] = useState([]);

  const [mainMediaIndex, setMainMediaIndex] = useState(0);
  const goodsDetailProviderData = {
    imageRatio: 'auto',
    layoutStyle: 'style1',
    productMedia: {
      mainMediaIndex,
      mediaList: goodCover,
      mainMediaSrc: currentMedia?.[0]?.url || '',
    },

    updateMainMediaIndex: setMainMediaIndex,
    updateProductMediaList: setGoodCover,
  };

  const getGoodsDetail = () => {
    goodsApi
      .getGoodsById({
        kdtId: kdtId || global_data?.kdtId,
        goodsId,
      })
      .then((res) => {
        setGoodsDetail(res);
        setCurrentMedia(res?.media);
        setGoodCover(res?.media || []);
        setMainMediaIndex(0);
        const { options = [] } = res;
        setGoodsMultipleSkuStatus(!!options.length);
      });
  };

  const monitorSkuInfo = (sku) => {
    // const cartInfo = goodsDetail?.variants.find((cur) => cur?.variantId === variantId);
    setCart(sku);
  };

  const getCurrentImg = () => {
    return currentMedia && currentMedia[0]?.url;
  };

  useEffect(() => {
    if (goodsId) {
      getGoodsDetail();
    }
  }, []);

  useEffect(() => {
    const productMedia = getMedia(goodsDetail?.media, cart, goodsDetail?.variants);
    setCurrentMedia(productMedia);
  }, [cart, goodsDetail?.variants]);

  const CloseSkuChoose = () => {
    return (
      <svg
        className='close-buy-now-icon'
        onClick={(event) => {
          triggerSkuBlock(false);
          event.stopPropagation();
        }}
        aria-hidden='true'
      >
        <use xlinkHref='#iconic-shanchu' />
      </svg>
    );
  };

  const attachPropsPrice = getTotalAttachPropsPrice(cart.goodsAdditionalItems);

  return (
    <ContextGoodDetail.Provider value={goodsDetailProviderData}>
      <Style>
        {isPC && (
          <div className='close-wrapper'>
            <CloseSkuChoose />
          </div>
        )}
        {goodsDetail && (
          <div className={cn('content-wrapper', { 'content-wrapper-for-h5': !isPC })}>
            <div className='sku-container animate-sku'>
              {isPC && (
                <div className='sku-goods-img' id='sku-img-wrapper'>
                  <ProductMedia currentMedia={currentMedia} goodsDetail={goodsDetail} isZoom={false} arrows={false} />
                </div>
              )}

              <div className='sku-block'>
                {isPC && (
                  <div className='goods-title-wrapper'>
                    <div className='goods-title'>{goodsDetail?.title}</div>
                    <p className='sku-close-line'>
                      <span className='price'>
                        {!cart?.variantId ? (
                          <HtGoodsDetailPrice attachPropsPrice={attachPropsPrice} goodsDetail={goodsDetail} />
                        ) : (
                          <HtGoodsSkuPrice
                            price={(cart?.price || 0) + attachPropsPrice}
                            attachPropsPrice={attachPropsPrice}
                            bestPriceInfo={cart?.bestPriceInfo}
                            compareAtPrice={cart?.compareAtPrice}
                            discountInfo={cart?.discountInfo}
                            memberInfo={cart?.memberInfo}
                            variantId={cart?.variantId}
                            item={cart}
                          />
                        )}
                      </span>
                    </p>
                  </div>
                )}
                {!isPC && (
                  <div className='goods-title-wrapper-h5'>
                    <div className='goods-main-info'>
                      <div className='img-wrapper'>
                        <img src={getCurrentImg()} />
                      </div>
                      <div className='title-wrapper'>
                        <div className='goods-title'>{goodsDetail?.title}</div>
                        <p className='sku-close-line'>
                          <span className='price'>
                            {!cart?.variantId ? (
                              <HtGoodsDetailPrice goodsDetail={goodsDetail} attachPropsPrice={attachPropsPrice} />
                            ) : (
                              <HtGoodsSkuPrice
                                price={(cart?.price || 0) + attachPropsPrice}
                                attachPropsPrice={attachPropsPrice}
                                bestPriceInfo={cart?.bestPriceInfo}
                                compareAtPrice={cart?.compareAtPrice}
                                discountInfo={cart?.discountInfo}
                                memberInfo={cart?.memberInfo}
                                variantId={cart?.variantId}
                              />
                            )}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div>
                      <CloseSkuChoose />
                    </div>
                  </div>
                )}

                <SkuSelect
                  innerRef={skuSelectRef}
                  currency={currency}
                  goodsDetail={goodsDetail}
                  goodsId={goodsId}
                  triggerSkuBlock={triggerSkuBlock}
                  enablePaymentButton={enablePaymentButton}
                  enableCartButton={!enablePaymentButton}
                  history={history}
                  setSkuPageCompleteStatus={setSkuPageCompleteStatus}
                  selectSkuInfoCallBack={monitorSkuInfo}
                  preVariantId={preVariantId}
                  preItemKey={preItemKey}
                  preNum={preNum}
                  onCartSkuChange={onCartSkuChange}
                  selectedAttachProps={selectedAttachProps}
                />
              </div>
            </div>
          </div>
        )}
      </Style>
    </ContextGoodDetail.Provider>
  );
};

export default withRouter(BuyNow);
