import styled from 'styled-components';

export const StyleAdditionalItems = styled.div`
  font-size: 0;
  .additional-rule {
    display: flex;
    /* align-items: center; */
    margin-top: 24px;
    gap: 8px;
    flex-wrap: wrap;

    &.en {
      flex-direction: column;
      justify-content: unset;
      align-items: unset;

      .additional-item-title {
        width: unset;
      }

      .additional-item-rules {
        max-width: 100%;
        box-sizing: border-box;
        width: fit-content;
      }
    }
  }

  .additional-item-title {
    font-size: 14px;
    line-height: 20px;
    color: rgba(var(--color_body_text_rgb), 0.8);
  }

  .additional-items-detail {
    width: 100%;
    display: flex;
    /* grid-template-columns: repeat(3, minmax(200px, 1fr)); */
    gap: 8px;
    margin-top: 8px;
    flex-wrap: wrap;
    max-width: 700px;
  }

  .additional-item-rules {
    display: flex;
    padding: 2px 4px;
    box-sizing: border-box;
    align-items: center;
    gap: 2px;
    border-radius: 4px;
    font-size: 12px;
    /* height: 20px; */

    .text {
      line-height: 14px;
      flex: 1;
    }

    &.default {
      background-color: rgba(var(--color_body_text_rgb), 0.04);
      color: rgba(var(--color_body_text_rgb), 0.6);
    }

    &.none {
      display: none;
    }

    &.success {
      background: rgba(var(--color_body_text_rgb), 0.04);
      color: var(--color_body_text);
      svg {
        fill: var(--color_body_text);
      }
    }

    &.error {
      background-color: rgba(236, 91, 86, 0.1);
      color: #ec5b56;
      svg {
        fill: #ec5b56;
      }
    }
  }
  .additional-item {
    display: flex;
    gap: 4px;
    padding: 8px;
    box-sizing: border-box;
    /* width: calc(50% - 6px); */
    box-sizing: border-box;
    position: relative;
    border-radius: var(--border_radius_form, 8px);
    border: 1px solid var(--color_text_field_border, #e6e6e6);
    background: var(--color_text_field, #fff);
    min-width: 200px;

    &.small {
      min-width: 170px;
    }

    .detail {
      display: flex;
      flex-direction: column;
      gap: 8px;
      flex: 1;
      width: 0;
      overflow-wrap: break-word;

      &.no-price {
        flex-direction: row;

        .title {
          width: 0;
        }
      }

      .title {
        color: var(--color_text_field_text, #333);
        font-size: 14px;
        line-height: 20px;
        flex: 1;
        display: flex;
        align-items: flex-start;
        font-weight: 400;
      }

      .price-count {
        display: flex;
        align-items: center;

        .price {
          color: var(--color_sale_text, #982424);
          font-size: 14px;
          line-height: 20px;
          flex: 1;
          width: 0;
          display: flex;
          gap: 2px;
          align-items: center;
          .plus-symbol {
            height: 14px;
            line-height: 14px;
          }
          .price-sale {
            height: 20px;
            display: flex;
          }
          .price-symbol,
          .price-integer,
          .price-decimal {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }

    &.selected {
      border-color: var(--color_button);
      background: rgba(var(--color_button_rgb), 0.1);

      .detail {
        .title {
          color: var(--color_button);
          font-weight: 500;
        }
      }
      .step-btn:not(.disabled) {
        border-color: var(--color_button);
        svg {
          fill: var(--color_button);
        }
      }
    }
  }
`;

export const StyleCounter = styled.div`
  display: flex;
  display: flex;
  align-items: center;
  user-select: none;

  .count {
    color: var(--color_text_field_text);
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    flex: 1;
    width: 24px;
    text-align: center;
  }

  .step-btn {
    display: flex;
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 100%;
    border: 1px solid var(--color_text_field_border, #e6e6e6);

    &.plus-one {
      border-color: var(--color_button);
      svg {
        fill: var(--color_button);
      }
    }

    svg {
      fill: var(--color_text_field_border, #e6e6e6);
    }

    &.disabled {
      border-color: rgba(var(--color_text_field_text_rgb), 0.2);
      background: rgba(var(--color_text_field_text_rgb), 0.04);
      svg {
        fill: rgba(var(--color_text_field_text_rgb), 0.2);
      }
    }
  }

  .minus-one-wrapper {
    position: absolute;
    right: 54px;
    height: 30px;
    width: 30px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: red;
  }
`;
