import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'zent';
import { buriedPoint } from 'utils/log-buried-point';
import { FormattedMessage, useIntl } from 'react-intl';
import { SHOP_CART } from '../../../util/local-storage/config';
import CartContent from './cart-content';
import { useCartList } from 'hook/cart';
import Style, { OperationStyle, CloseStyle, CartStyle } from './style';
import GoodsAcceptPayment from '../goods-item/goods-accept-payment';
import shopApi from '../../../api/shop';
import { TRACK } from 'const';
import MemberInfo from './member-info';
import CartGoodsList from 'cpn/cart/cart-goods-list';
import { useRequireLogin } from '../require-login-wrapper';
import HtDialog from 'components/standard/dialog';
import { useI18n } from 'hook/intl';
import { CartGoodsLimitRemindDrawer } from './cart-goods-limit-remind-drawer';

// import { MemberInfo } from '../../member/components/member-info';

const ShoppingCart = ({ onClose, initData, history }) => {
  const intl = useIntl();
  const { $fm } = useI18n(intl);
  const currency = initData?.shopSetting?.currency;
  const language = initData?.shopSetting?.language;
  const customerId = initData?.userInfo?.customerId;

  const {
    loading,
    cartList, // 购物车所有的商品列表
    discountInfo, // 折扣信息
    discountItems, // 折扣商品
    noDiscountItems, // 非折扣商品
    allGiftGoods, // 买X送Y专用，所有的Y
    giftGoods, // 买X送Y专用
    selectedGoods, // 买X送Y专用
    isEditGift, // 买X送Y专用
    onSwitch, // 买X送Y专用
    onConfirm, // 买X送Y专用
    onChangeGift, // 买X送Y专用
    onSwitchSelect, // 买X送Y专用
    onChange,
    onDelete,
    totalGift,
    isGiftFit,
    discountQuantity,
    onCartSkuChange,
    initialData,
    multipleAutoDiscountsCart,
  } = useCartList({
    customerId,
    needLoading: true,
  });
  const [acceptPaymentNum, setAcceptPaymentNum] = useState(1);

  const [expiredIdList, setExpiredIdList] = useState(null);
  const [expiredList, setExpiredList] = useState(null);
  const [animate, setAnimate] = useState(true);

  // 强制登录校验判断
  const { checkedRequireLogin } = useRequireLogin();

  const [visibleGoodsLimitDrawer, setVisibleGoodsLimitDrawer] = useState(false); // 限购提醒窗口
  const hasLimited = useRef(false); // 是否有被限购

  // 去结算
  const toCheckout = (event) => {
    if (hasLimited.current) {
      event.preventDefault();
      event.stopPropagation();
      setVisibleGoodsLimitDrawer(true);
    }
  };

  useEffect(() => {
    // const list = noDiscountItems.concat(discountItems).map((item) => {
    //     const { goodsId, variantId } = item;
    //     return { goodsId, variantId };
    // });
    // if (list?.length === 0) return;
    let variantIdList = [];
    let variantList = [];
    variantIdList = cartList.filter((item) => item?.goodsStatus !== 'ok').map((item) => item?.variantId);
    variantList = cartList.filter((item) => item?.goodsStatus !== 'ok').map((item) => item);
    setExpiredIdList(variantIdList);
    setExpiredList(variantList);
    // const params = { variantIds: list, kdtId: window?.global_data?.kdtId };
    // shopApi.getGoodsSkuStatus(params).then((data) => {
    //     const noInventoryList = data.filter((item) => item?.inventory === 0);
    //     noInventoryList.forEach((item) => {
    //         variantIdList.push(item?.variantId);
    //         return item?.goodsId;
    //     });
    //     setExpiredIdList(variantIdList);
    // });
  }, [cartList]);

  useEffect(() => {
    // 响应事件
    const handleClose = ({ data: eventName }) => {
      if (eventName === 'closeRightShoppingCart') {
        setAnimate(false);
      }
    };
    window.addEventListener('message', handleClose);
    document.querySelector('body').style.overflow = 'hidden';
    return () => {
      document.querySelector('body').style.overflow = 'auto';
      window.removeEventListener('message', handleClose);
    };
  }, []);

  const normalDiscountItems = expiredIdList
    ? discountItems.filter((item) => !expiredIdList.includes(item?.variantId))
    : []; // 过滤掉过期的商品
  const normalNoDiscountItems = expiredIdList
    ? noDiscountItems.filter((item) => !expiredIdList.includes(item?.variantId))
    : [];

  const itemNum = cartList.length;

  const cartContentProps = {
    loading,
    selectedGoods,
    onChangeGift,
    currency,
    discountInfo,
    isEditGift,
    allGiftGoods,
    giftGoods,
    onSwitch,
    onConfirm,
    onSwitchSelect,
    language,
    // expiredVariantIdList,
    multipleAutoDiscountsCart,
    cartList,
    isDrawer: true,
    itemProps: {
      onChangeNum: onChange,
      onDelete,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      updateCartList: () => { },
      discountCode: discountInfo?.discountCode,
      onToggleSelect: onSwitchSelect,
      onCartSkuChange,
      isMobile: true,
      initialData,
    },
  };

  return (
    <Style className={animate ? 'show-cart-animate' : 'close-cart-animate'}>
      {/* 关闭按钮 */}
      <div className='cart-close'>
        <CloseStyle className='cart-close' onClick={onClose}>
          <div className='sidebar-goods-number'>
            {' '}
            <FormattedMessage id='goods.cart.goods_count' defaultMessage='商品数' /> ({itemNum})
          </div>
          <svg className='icon delete-icon-close' aria-hidden='true'>
            <use xlinkHref='#iconic-shanchu' />
          </svg>
        </CloseStyle>
        <MemberInfo history={history} initialData={initData} />
      </div>
      {/* 商品列表 */}

      <CartStyle className='shopping-middle-content'>
        {/* 购物车侧边栏 */}
        <CartGoodsList {...cartContentProps} domClassName='show-cart-animate' />
      </CartStyle>

      {/* 结账按钮 */}
      {!!cartList?.length && (
        <OperationStyle className='cart-operation'>
          {acceptPaymentNum > 0 && (
            <div className='icon-wrapper'>
              <GoodsAcceptPayment setAcceptPaymentNum={setAcceptPaymentNum} type='nowrap' align='center' />
            </div>
          )}
          <div className='cart-operation-content'>
            <Button type='primary' className={TRACK.BuyOnceButton} onClickCapture={checkedRequireLogin(toCheckout)}>
              <a
                onClick={() => {
                  buriedPoint('Cartview_gocheckout', { source: { cartList }, goodsList: cartList });
                }}
                href={`/order/subscribe?step=info&refer=${SHOP_CART}`}
                rel='nofollow'
              >
                {$fm('goods.buy.car_once', '去结账')}
              </a>
            </Button>
          </div>
          <div>
            <Button className='show-cart' onClickCapture={checkedRequireLogin} onClick={onClose}>
              <a href='/shopping-cart' rel='nofollow'>
                {$fm('goods.cart.preview', '查看购物车')}
              </a>
            </Button>
          </div>
        </OperationStyle>
      )}
      <CartGoodsLimitRemindDrawer
        $fm={$fm}
        visible={visibleGoodsLimitDrawer}
        setVisible={setVisibleGoodsLimitDrawer}
        cartList={cartList}
        hasLimited={hasLimited}
      />
    </Style>
  );
};

export default ShoppingCart;
