import React, { useContext, useState } from 'react';
import HtDialog from '../../standard/dialog';
import LoginWithCode from './components/login-with-code';
import LoginWithPassword from './components/login-with-password';
import { LOGIN_METHOD } from './const';
import { openRegisterDialog } from '../register-dialog';
import RootContext from 'client/pages/context/root';
import ForgetPasswordDialog from 'client/components/page/forgot-password-dialog';
import { handLoginSuccess } from 'helper/account';
import { useIntl } from 'react-intl';

export interface LoginDialogInfoParams {
  visible: boolean;
  callbackUrl?: string;
}

function LoginDialog(props) {
  const intl = useIntl();
  const { theme } = useContext(RootContext);

  const { visible, onClose, language, setLoginDialogInfo, withMobileLogin, callbackUrl, isFromAffiliate } = props;
  const [showForgetPasswordDialog, setShowForgetPasswordDialog] = useState(false);

  const onLoginSuccess = async () => {
    handLoginSuccess({ intl, isFromAffiliate, callbackUrl, theme });
  };

  const onForgotPassword = () => {
    setShowForgetPasswordDialog(true);
  };

  const onRegister = (params = {}) => {
    openRegisterDialog({
      ...params,
      theme,
      intl,
      withMobileLogin,
      isFromAffiliate,
    });
  };

  const titleTabs = [
    {
      id: LOGIN_METHOD.loginWithCode,
      text: intl.formatMessage({
        id: '72bcbf867d5b47bdac761b62b24937ed',
        defaultMessage: '验证码登录',
      }),
    },
    {
      id: LOGIN_METHOD.loginWithPassword,
      text: intl.formatMessage({
        id: 'd879e9d948c544b297fe2cec35bfbc22',
        defaultMessage: '密码登录',
      }),
    },
  ];
  const bodyTabContents = [
    {
      id: LOGIN_METHOD.loginWithCode,
      content: (
        <LoginWithCode
          intl={intl}
          onClose={onClose}
          onRegister={onRegister}
          language={language}
          callbackUrl={callbackUrl}
          onLoginSuccess={onLoginSuccess}
        />
      ),
    },
    {
      id: LOGIN_METHOD.loginWithPassword,
      content: (
        <LoginWithPassword
          intl={intl}
          onClose={onClose}
          language={language}
          onRegister={onRegister}
          onForgotPassword={onForgotPassword}
          withMobileLogin={withMobileLogin}
          callbackUrl={callbackUrl}
          onLoginSuccess={onLoginSuccess}
        />
      ),
    },
  ];
  return (
    <>
      <HtDialog
        visible={visible}
        onClose={onClose}
        title={intl.formatMessage({
          id: 'd879e9d948c544b297fe2cec35bfbc22',
          defaultMessage: '密码登录',
        })}
        titleTabs={withMobileLogin ? titleTabs : []}
        bodyTabContents={withMobileLogin ? bodyTabContents : []}
        maskClosable={false}
      >
        {withMobileLogin ? null : (
          <LoginWithPassword
            intl={intl}
            onClose={onClose}
            language={language}
            onRegister={onRegister}
            onForgotPassword={onForgotPassword}
            withMobileLogin={withMobileLogin}
            callbackUrl={callbackUrl}
            isFromAffiliate={isFromAffiliate}
            onLoginSuccess={onLoginSuccess}
          />
        )}
      </HtDialog>
      <ForgetPasswordDialog
        visible={showForgetPasswordDialog}
        withMobileLogin={withMobileLogin}
        onClose={() => setShowForgetPasswordDialog(false)}
        setLoginDialogInfo={setLoginDialogInfo}
        isFromAffiliate={isFromAffiliate}
      />
    </>
  );
}

export default LoginDialog;
