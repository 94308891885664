import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
@media screen and (max-width: 560px) {
  .ht-dialog {
    min-width: 91% !important;
  }
}
.ht-dialog {
    max-width: 91% !important;
    .zent-dialog-r-footer {
      border-top: 1px solid #dcdee0;
      padding: ${(props) => props.theme.getSpace(props.theme.pCardsNew)} ${(props) =>
  props.theme.getSpace(props.theme.pCardsNew)} 0;
    }
    .zent-dialog-r-title {
      font-size: ${(props) => props.theme.getSpace(props.theme.h10)};
      padding: 0 ${(props) => props.theme.getSpace(props.theme.pCardsNew)} ${(props) =>
  props.theme.getSpace(props.theme.pCardsNew)};
    }
    .zent-dialog-r-body {
      padding: ${(props) => props.theme.getSpace(props.theme.pCardsNew)}
    }
    &.zent-dialog-r {
      padding: ${(props) => props.theme.getSpace(props.theme.pCardsNew)} 0 !important;
    }
    .zent-dialog-r-close.zent-dialog-r-has-title {
      top: 20px;
    }
}
.ht-dialog-small {
  min-width: 460px !important;
}
@media screen and (min-width: 769px) {
  .ht-dialog {
    .zent-dialog-r-body {
      padding: 24px;
    }
    .zent-dialog-r-footer {
      border-color: rgba(var(--color_body_text_rgb), 0.08);
      padding: 20px 24px 0 24px;
    }
  }
}
`;

export const DialogHeaderWidthIcon = styled.div`
  display: flex;
  align-items: center;
`;
export const DialogHeaderWidthIconTitle = styled.span`
  margin-left: 10px;
`;
