import styled, { createGlobalStyle } from 'styled-components';

export const DialogStyle = createGlobalStyle`
  .zent-dialog-r-anchor {
    .zent-dialog-r-backdrop,
    .zent-dialog-r-wrap {
      z-index: 9999;
    }
    .remindr-drawer-body {
      .min-limit + .max-limit {
        margin-top: 12px;
      }
      .min-limit {
        &.attach-props {
          margin-top: 12px;
        }
      }
      .max-limit {
        .max-limit-tips {
          font-size: 14px;
          line-height: 20px;
          color: var(--color_activity_label);
        }
      }
      .limit-tips {
        font-size: 14px;
        line-height: 20px;
        color: rgba(var(--color_text_rgb), 0.6);
      }
      .limit-goods-list {
        margin-top: 12px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 12px;
        background-color: rgba(var(--color_text_rgb), 0.04);
        border-radius: var(--border_radius_card);
        .limit-goods-item {
          display: flex;
          gap: 12px;
          .goods-cover {
            width: 80px;
            height: 80px;
            margin-right: 0;
            flex-shrink: 0;
          }
          &__info {
            flex: 1;
            width: 0;
            display: flex;
            flex-direction: column;
            .info-name {
              font-size: 14px;
              line-height: 20px;
              color: var(--color_text);
              white-space: normal;
              text-overflow: ellipsis;
              overflow: hidden;
            }
            .info-sku {
              font-size: 14px;
              line-height: 20px;
              color: rgba(var(--color_text_rgb), 0.6);
            }
            .info-num {
              margin-top: auto; 
              display: flex;
              align-items: center;
              gap: 8px;
              font-size: 14px;
              line-height: 20px;
              color: rgba(var(--color_text_rgb), 0.6);
              .info-min-purchase-num {
                font-size: 14px;
                line-height: 20px;
                color: var(--color_activity_label);
              }
            }
          }
        }
      }
      .back-btn {
        margin-top: 12px;
        width: 100%;
      }
    }
  }
`;
