import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';

export const Style = styled.div`
  .location-overview {
    font-size: 12px;
    line-height: 18px;
    padding: 12px;
    background-color: rgba(var(--color_body_text_rgb), 0.04);
    border-radius: var(--border_radius_other);
    color: var(--color_body_text);
    display: flex;
    .location-item {
      &__left {
        flex: 1;
      }
      &__name {
        font-weight: 500;
      }
      &__detail {
        color: rgba(var(--color_body_text_rgb), 0.8);
        margin-top: 4px;
      }
      &__right {
        flex-shrink: 0;
        margin-left: 24px;
      }
      &__more {
        display: flex;
        align-items: center;
        cursor: pointer;
        color: rgba(var(--color_body_text_rgb), 0.6);
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .icon-arrow-right {
      width: 16px;
      height: 16px;
      fill: var(--color_body_text);
    }
  }
`;

export const LocationStyle = styled.div`
  .location-dialog-container {
    .search-container {
      display: flex;
      align-items: center;
      padding-bottom: 16px;
      color: var(--color_body_text);
      border-bottom: 1px solid rgba(var(--color_body_text_rgb), 0.2);
      .icon-search {
        width: 24px;
        height: 24px;
        fill: var(--color_body_text);
      }
      .search-input-inner {
        flex: 1;
        height: 24px;
        line-height: 24px;
        border: none;
        outline: none;
        padding: 0;
        margin: 0 6px;
        background-color: transparent;
      }
      .search-input-inner::placeholder {
        color: rgba(var(--color_body_text_rgb), 0.4);
      }
      .search-input-inner::-webkit-input-placeholder {
        color: rgba(var(--color_body_text_rgb), 0.4);
      }
      .search-input-inner:-moz-placeholder {
        color: rgba(var(--color_body_text_rgb), 0.4);
      }
    }
  }
  .location-list {
    overflow-y: auto;
    max-height: 60vh;
    min-height: 360px;
    .location-info-item {
      display: flex;
      align-items: stretch;
      padding: 24px 0;
      cursor: pointer;
      + .location-info-item {
        border-top: 1px solid var(--color_borders);
      }
      &__radio {
        display: flex;
        align-items: center;
        margin-right: 12px;
      }
      &__left {
        flex: 1;
        margin-right: 8px;
      }
      &__title {
        color: var(--color_body_text);
        font-weight: 500;
        margin-bottom: 12px;
      }
      &__address,
      &__tel {
        display: flex;
        align-items: center;
        margin-top: 4px;
        font-size: 12px;
        line-height: 18px;
        color: rgba(var(--color_body_text_rgb), 0.6);
        .icon {
          width: 12px;
          height: 12px;
          fill: var(--color_body_text);
          margin-right: 4px;
          flex-shrink: 0;
        }
      }
      &__right {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        color: rgba(var(--color_body_text_rgb), 0.4);
        .icon {
          width: 16px;
          height: 16px;
          fill: rgba(var(--color_body_text_rgb), 0.4);
        }
      }
    }
  }
  .location-detail {
    min-height: 400px;
    .location-info {
      &__title {
        font-size: 14px;
        line-height: 20px;
        color: var(--color_body_text);
        font-weight: 600;
        margin-bottom: 16px;
      }
      &__address,
      &__tel {
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 18px;
        margin-top: 8px;
      }
      .icon {
        flex-shrink: 0;
        width: 12px;
        height: 12px;
        fill: var(--color_body_text);
        margin-right: 4px;
        flex-shrink: 0;
      }
    }
  }
`;

export const DialogStyle = createGlobalStyle`
  .ht-dialog.location-dialog {
    max-width: 640px !important;
    min-height: 560px;
    .icon-back {
      width: 20px;
      height: 20px;
      fill: var(--color_body_text);
      cursor: pointer;
    }
  }
`;
