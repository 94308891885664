import React, { useState, useEffect, useCallback } from 'react';
import { Style, LocationStyle, DialogStyle } from './style';
import { formatLocationText } from 'utils/global';
import { Icon } from 'components/base';
import { debounce } from 'lodash';
import HtDialog from 'components/standard/dialog';

interface ILocation {
  address1: string;
  address2: string;
  city: string;
  country: string;
  countryCode: string;
  isDefault: number;
  isEnablePickup: number;
  locationId: number;
  name: string;
  phone: string;
  province: string;
  provinceCode: string;
  zip: string;
}

interface ILocationFilter {
  searchInput: string;
}

export const LocationInfo = (props) => {
  const { $fm, locationList } = props;

  const [visibleDialog, setVisibleDialog] = useState<boolean>(false);

  // 关闭弹窗
  const onClose = () => {
    setVisibleDialog(false);
  };

  if (locationList.length === 0) return null;

  return (
    <Style>
      <div className='location-overview'>
        <div className='location-item__left'>
          <div className='location-item__name'>{locationList[0].name}</div>
          <div className='location-item__detail'>{formatLocationText(locationList[0])}</div>
        </div>
        <div className='location-item__right'>
          <span className='location-item__more' onClick={() => setVisibleDialog(true)}>
            {$fm('1eb05c13918c42df9a8b22643d725b0f', '查看可使用门店')}
            <Icon name='iconic-xiala' className='icon icon-arrow-right' />
          </span>
        </div>
      </div>
      <LocationDialog $fm={$fm} visibleDialog={visibleDialog} onClose={onClose} locationList={locationList} />
    </Style>
  );
};

export const LocationDialog = (props) => {
  const { $fm, visibleDialog, onClose, locationList = [] } = props;

  const [filterLocationList, setFilterLocationList] = useState<ILocation[]>(locationList || []);
  const [filter, setFilter] = useState<ILocationFilter>({
    searchInput: '',
  });
  const [searchInput, setSearchInput] = useState<string>('');
  const [isView, setIsView] = useState<boolean>(false);
  const [locationDetail, setLocationDetail] = useState({});

  // 搜索词防抖
  const changeFilterSearchInput = useCallback(
    debounce((searchInput) => {
      setFilter({
        searchInput,
      });
    }, 200),
    [],
  );
  useEffect(() => {
    changeFilterSearchInput(searchInput);
  }, [searchInput]);

  // 搜索
  useEffect(() => {
    const { searchInput } = filter;
    if (searchInput) {
      const filterList = locationList.filter((item) => item.name.toLowerCase().includes(searchInput.toLowerCase()));
      setFilterLocationList(filterList);
    } else {
      setFilterLocationList(locationList);
    }
  }, [locationList, filter]);

  // 查看详情
  const toViewDetail = (item) => {
    setIsView(true);
    setLocationDetail(item);
  };

  // 关闭弹窗
  const close = () => {
    onClose();
  };

  return (
    <HtDialog
      className='location-dialog'
      visible={visibleDialog}
      titleIcon={isView ? <Icon onClick={() => setIsView(false)} className='icon icon-back' name='iconic-zuoyouqiehuan' /> : null}
      title={
        isView
          ? $fm('d04fdd3d71a04f4497704267566f837c', '门店详情')
          : $fm('e1c05b6f1f45448bb9ed1038d4af69f3', '全部门店')
      }
      onClose={close}
    >
      <DialogStyle />
      <LocationStyle>
        <div className='location-dialog-container'>
          {isView ?
            <LocationDetail locationDetail={locationDetail} /> :
            <>
              <div className='search-container'>
                <Icon className='icon icon-search' name='iconic-sousuo' />
                <input
                  className='search-input-inner'
                  type='text'
                  placeholder={$fm('aa08bfe60e2d4bd48780bd10ce2f521e', '请输入门店名称')}
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </div>
              <div className='location-list'>
                {
                  filterLocationList.map((item) => (
                    <div
                      key={item.locationId}
                      className='location-info-item'
                    >
                      <div className='location-info-item__left'>
                        <div className='location-info-item__title'>{item.name}</div>
                        <div className='location-info-item__address'>
                          <Icon className='icon' name='icondizhi' />
                          <span>{formatLocationText(item)}</span>
                        </div>
                        <div className='location-info-item__tel'>
                          <Icon className='icon' name='iconic_phone' />
                          <span>{item.phone}</span>
                        </div>
                      </div>
                      <div onClick={() => toViewDetail(item)} className='location-info-item__right'>
                        <span>{$fm('common_detail', '详情')}</span>
                        <Icon className='icon icon-arrow-right' name='iconic-xiala' />
                      </div>
                    </div>
                  ))
                }
              </div>
            </>
          }
        </div>
      </LocationStyle>
    </HtDialog>
  );
};

function LocationDetail(props) {
  const { locationDetail } = props;

  return (
    <div className='location-detail'>
      <div className='location-info'>
        <div className='location-info__title'>{locationDetail?.name}</div>
        <div className='location-info__address'>
          <Icon className='icon' name='icondizhi' />
          <span>{formatLocationText(locationDetail)}</span>
        </div>
        <div className='location-info__tel'>
          <svg className='icon' aria-hidden='true'>
            <use xlinkHref='#iconic_phone' />
          </svg>
          <span>{locationDetail?.phone}</span>
        </div>
      </div>
    </div>
  );
}