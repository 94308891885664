import { isObject, flatMapDeep } from 'lodash';

export enum ValidType {
  None = 'none',
  Default = 'default',
  Error = 'error',
  Success = 'success',
}

// 拼接附加项的选中名称+数量的字符串
export const getGoodsAdditionalItemsSelectedText = (goodsAdditionalItemsSelected, attachProps) => {
  const values = goodsAdditionalItemsSelected || {};
  return attachProps
    .map((item) => {
      const { propId, optionRules = [] } = item;
      const propSelected = values[propId] || {};
      let total = 0;
      const text = optionRules
        .map((option) => {
          const { optionTitle, optionId } = option;
          const quantity = propSelected[optionId]?.quantity || 0;
          total += quantity;
          return {
            quantity,
            optionTitle,
          };
        })
        .filter((option) => option.quantity > 0)
        .map((option) => `${option.optionTitle}×${option.quantity}`)
        .join(',');

      return {
        propId,
        total,
        text,
      };
    })
    .filter((attachProp) => attachProp.total > 0)
    .map((attachProp) => attachProp.text)
    .join(',');
};

export const getTotalQuantityArray = (items) => {
  return flatMapDeep(items, (value, key) => {
    if (key === 'quantity') {
      return [value];
    } else if (isObject(value)) {
      return getTotalQuantityArray(value);
    }
    return [];
  });
};

export const getTotalQuantity = (propId, cart) => {
  const items = cart.goodsAdditionalItems || {};
  const totalQuantityArray = getTotalQuantityArray(items[propId]);
  const totalQuantity = totalQuantityArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  return totalQuantity;
};

export const getAdditionalItemRulesValidType = ({ propId, propRule, cart, isInitNeedCheck = false }) => {
  let { maxOptionQuota, minOptionQuota, multi, required } = propRule;
  const isInit = !isInitNeedCheck && !cart.goodsAdditionalItems?.[propId]; // 初始状态
  // 开启了多选
  if (multi === 1 && required === 0) {
    if (maxOptionQuota === undefined && minOptionQuota === undefined) {
      // 没有设置限制
      return isInitNeedCheck ? { valid: ValidType.Success } : { valid: ValidType.None };
    }
    if (isInit) {
      return { valid: ValidType.Default };
    }
    minOptionQuota = minOptionQuota || 0;
    maxOptionQuota = maxOptionQuota || 1000000;
    const totalQuantity = getTotalQuantity(propId, cart);
    if (totalQuantity >= minOptionQuota && totalQuantity <= maxOptionQuota) {
      return { valid: ValidType.Success };
    }
    return { valid: ValidType.Error, totalQuantity };
  }
  if (multi === 0 && required === 0) {
    return { valid: ValidType.None };
  }
  // 开启了必选,且非初始状态
  if (required === 1 && !isInit) {
    const totalQuantity = getTotalQuantity(propId, cart);
    return totalQuantity > 0 ? { valid: ValidType.Success } : { valid: ValidType.Error, totalQuantity };
  }

  return { valid: ValidType.Default };
};

// 返回没有下架的附加项
export const getValidAttachProps = (attachProps) => {
  if (!attachProps) {
    return [];
  }
  return attachProps?.reduce((arr, item) => {
    const optionRules = item.optionRules.filter((i) => i.isActivated === 1);
    if (optionRules.length > 0) {
      arr.push({
        ...item,
        optionRules,
      });
    }
    return arr;
  }, []);
};

// 输出附加项限购规格校验结果
export const getAdditionalItemsValidType = (attachPropsParams, cart, $fm) => {
  const attachProps = getValidAttachProps(attachPropsParams);
  return attachProps.map((item) => {
    const { propId, propRule, title } = item;
    const { valid, totalQuantity } = getAdditionalItemRulesValidType({ propId, propRule, cart, isInitNeedCheck: true });
    return {
      propId,
      title,
      valid,
      toast: totalQuantity === 0 ? $fm('a0ba86d9622c4e62955f5cec6ddd7ff0', '请选择「{title}」', { title }) : undefined,
    };
  });
};

export const getTotalAttachPropsPrice = (goodsAdditionalItemsSelected = {}) => {
  let totalPrice = 0;
  Object.keys(goodsAdditionalItemsSelected).forEach((propId) => {
    const option = goodsAdditionalItemsSelected[propId];
    Object.keys(option).forEach((optionId) => {
      const { price, quantity } = option[optionId];
      totalPrice += price * quantity;
    });
  });
  return totalPrice;
};
