import { MULTI_LANGUAGE_COOKIE_NAME_PREFIX, SWITCH_STATUS } from '../constant/index';
import LocaleCode from 'locale-code';

export const getMultiLanguageCookieName = (kdtId) => {
  return `${MULTI_LANGUAGE_COOKIE_NAME_PREFIX}_${kdtId}`;
};

// 解决兼容性问题 zh-CN 有到浏览器获取到底是zh-cn,而多语言文件存储到是zh-CN,
// 把 - 后面的转换成大写
export const formateLacale = (locale) => {
  return locale.replace(/-(\w+)/, function () {
    return arguments[0] && arguments[0].toUpperCase();
  });
};

export const getBrowserDefaultLanguage = (ctx) => {
  const acceptLanguage = ctx.headers['accept-language'];
  if (acceptLanguage) {
    const browserDefaultLanguage = acceptLanguage.split(',')[0]?.split(';')[0];
    const formate = formateLacale(browserDefaultLanguage);
    // const language = LocaleCode.validateLanguageCode(formate) ? LocaleCode.getLanguageCode(formate) : formate;
    // return language === 'zh' ? formate : language;
    return formate;
  }
  return null;
};

const matchLanguage = (languages, browserLanguage) => {
  if (!browserLanguage) return '';

  // 首先尝试完全匹配
  const exactMatch = languages.find(item => item.language?.toLowerCase() === browserLanguage?.toLowerCase());
  if (exactMatch) {
    return exactMatch.language;
  }

  // 提取基础语言代码
  const baseLanguage = browserLanguage?.split?.('-')?.[0]?.toLowerCase();
  // 如果没有完全匹配，尝试匹配基础语言代码
  const baseMatch = languages.find(item => item.language?.toLowerCase().split('-')[0] === baseLanguage);
  if (baseMatch) {
    return baseMatch.language;
  }

  // 没有匹配到
  return '';
}

const getLanuage = ({
  languageList = [],
  browserLanguage,
  cacheLanguage,
  shopSettingLanguage,
  browserSwitch = SWITCH_STATUS.ON,
}) => {
  let language = '';
  // 如果有缓存， 先判断缓存在languageList里面吗，如果在，就用，如果不在，就用浏览器默认的
  // 缓存语言，浏览器语言， 语言列表， 店铺默认语言
  if (languageList?.length > 0) {
    let canUseBrowserLanguage = false;
    let canUseCacheLanguage = false;
    if (browserSwitch === SWITCH_STATUS.ON) {
      canUseBrowserLanguage = languageList.findIndex((item) => item.language === browserLanguage) !== -1;
    }
    if (cacheLanguage) {
      canUseCacheLanguage = languageList.findIndex((item) => item.language === cacheLanguage) !== -1;
    }
    if (!canUseBrowserLanguage && !canUseCacheLanguage) {
      language = shopSettingLanguage;
    } else if (canUseBrowserLanguage && !canUseCacheLanguage) {
      language = browserLanguage;
    } else if (!canUseBrowserLanguage && canUseCacheLanguage) {
      language = cacheLanguage;
    } else {
      language = cacheLanguage;
    }
  } else {
    language = shopSettingLanguage;
  }

  return language;
};

export const getServerLanguage = (baseData = {}, shopSetting = {}, ctx) => {
  const cacheLanguage = baseData.multiLanguageInfo?.multiLanguage;
  const languageList = baseData.multiLanguageInfo?.languageList;
  const browserSwitch = baseData.multiLanguageInfo?.browserSwitch;
  const shopSettingLanguage = shopSetting.language;
  const browserLanguage = matchLanguage(languageList, getBrowserDefaultLanguage(ctx))
  return getLanuage({ languageList, browserLanguage, cacheLanguage, shopSettingLanguage, browserSwitch });
};

export const getClientLanguage = (initialData = {}) => {
  const { multiLanguageInfo = {}, shopSetting = {} } = initialData;
  const formate = formateLacale(window.navigator.language);
  const browserLanguage = LocaleCode.validateLanguageCode(formate) ? LocaleCode.getLanguageCode(formate) : formate;
  const { languageList, multiLanguage: cacheLanguage, browserSwitch } = multiLanguageInfo;
  const shopSettingLanguage = shopSetting.language;
  return getLanuage({ languageList, browserLanguage, cacheLanguage, shopSettingLanguage, browserSwitch });
};
