import React from 'react';
import { Styled } from './style';
import cn from 'classnames';
import { Icon } from 'components/base';
import { getUrl } from 'utils/formate';

export const PicAdv = props => {
  const {
    info = {},
    onClose,
    storePopAfterShow,
  } = props;

  const { banner_pc, banner_h5, pic_url, pic_max_width_pc = 560, close_btn_position } = info;

  const linkTo = () => {
    if (!pic_url) return;
    onClose();
    storePopAfterShow();
    const url = pic_url ? getUrl(JSON.parse(pic_url).url) : pic_url;
    window.location.href = url || '#';
  };

  return (
    <Styled>
      <div className='pic-adv-content'>
        <div onClick={onClose} className={cn("icon-close", {'bottom-center': close_btn_position === 'bottom-center'})}>
          <Icon className='close' size='xSmall' name='iconjichu-guanbi'/>
        </div>
        <img style={{ maxWidth: pic_max_width_pc }} onClick={linkTo} className='pic-adv-cover pc-adv' src={banner_pc} />
        <img onClick={linkTo} className='pic-adv-cover h5-adv' src={banner_h5} />
      </div>
    </Styled>
  )
}