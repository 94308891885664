import styled from 'styled-components';

export default styled.div`
  padding: 16px;
  background-color: rgba(var(--color_body_text_rgb), 0.04);
  border-radius: var(--border_radius_other);
  .rule-item-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .rule-item {
    display: flex;
    svg {
      flex-shrink: 0;
      width: 20px;
      height: 20px;
      margin-right: 12px;
      fill: var(--color_body_text);
    }
    .rule-content {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
      font-size: 14px;
      line-height: 20px;
      word-break: break-word;
      .title {
        color: var(--color_body_text);
        font-weight: 500;
      }
      .desc {
        color: rgba(var(--color_body_text_rgb), 0.6);
      }
    }
  }
`;
