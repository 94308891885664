import React from 'react';
import { GoodsTitle, MultipleSpecifications, ImageBox, DeleteBtn, GoodsTag } from 'cpn/goods-item';
import cn from 'classnames';
import Style from './style';
import { useRootContext } from 'client/pages/context/root';
import { useIntl } from 'react-intl';
import shopApi from 'api/shop';
import Toast from 'cpn/toast';

export const SimpleGoodsInfo = ({
  item,
  canDelete = false,
  onDelete = () => { },
  index = 0,
  updateCartList = () => { },
  onToggleSelect = () => { },
  onCartSkuChange = () => { },
  inventory,
  isExpiredGoodsStatus = false,
  isGroupBuying,
  showSelected = true,
  className = '',
}) => {
  const {
    imageUrl = '',
    title = '',
    sku = [],
    optionValues = [],
    handle = '',
    variantId,
    goodsId,
    quantity,
    attachProps = [],
    cartStatus,
    itemKey = '',
  } = item || {};

  const intl = useIntl();
  const { setLoginDialogInfo } = useRootContext();
  const onDeleteAndToWish = (variantId, index) => {
    const { userInfo = {}, kdtId } = window?.global_data;
    if (!userInfo?.customerId) {
      setLoginDialogInfo({
        visible: true,
      });
      return;
    }
    const params = { customerId: userInfo?.customerId, goodsId, kdtId };
    shopApi.addToWishList(params).then(() => {
      onDelete(item, index);
      Toast.info(intl.formatMessage({ id: '6630fe04e7874f12a012e92e415f5fdf' }), 800);
    });
  };

  return (
    <Style className={className}>
      {canDelete && showSelected && (
        <div
          onClick={() => {
            onToggleSelect(item);
          }}
          className={cn('select-wrapper', `select-wrapper-status-${item?.hasSelected ? 'selected' : 'no-selected'}`)}
        >
          {item?.hasSelected ? (
            <svg width={16} height={16}>
              <use xlinkHref='#icongouxuankuang' />
            </svg>
          ) : (
            <svg width={16} height={16}>
              <use xlinkHref='#iconweigouxuan' />
            </svg>
          )}
        </div>
      )}

      <ImageBox
        imageUrl={imageUrl}
        handle={handle || title}
        quantity={item.goodsStatus === 'sell_out' || item.goodsStatus === 'fully_sell_out' ? 0 : inventory}
        isExpiredGoodsStatus={isExpiredGoodsStatus}
        style={{
          flexShrink: 0,
          height: '120px',
          width: '120px',
        }}
        cartStatus={cartStatus}
      />
      <div className='info'>
        <div className='wrap'>
          <GoodsTitle title={title} />
          <MultipleSpecifications
            optionTitles={sku}
            optionValues={optionValues}
            className='flex-not1'
            canChangeSku
            goodsId={goodsId}
            preVariantId={variantId}
            preItemKey={itemKey}
            updateCartList={updateCartList}
            quantity={quantity} // 这里的quantity是数量， props传过来的是库存
            canDelete={canDelete}
            onCartSkuChange={onCartSkuChange}
            attachProps={attachProps}
            cartStatus={cartStatus}
          />
          <GoodsTag isGroupBuying={isGroupBuying} />
          {cartStatus?.errorTips && (
            <span className='warn-tip'>
              {intl.formatMessage({
                id: cartStatus?.errorTips,
                defaultMessage: '商品已不能购买',
              })}
            </span>
          )}
        </div>
        {canDelete && (
          <div className='delete-wrapper'>
            <DeleteBtn
              className='mt10'
              onDelete={() => {
                onDelete(item, index);
                Toast.info(intl.formatMessage({ id: 'general.delete_success' }), 800);
              }}
            />
            <DeleteBtn
              className='mt10'
              onDelete={() => {
                onDeleteAndToWish(item, index);
              }}
              toWish
            />
          </div>
        )}
      </div>
    </Style>
  );
};
