/**
 * api层统一使用的ajax基类封装
 */
import { set } from 'lodash';
import ajax from './ajax';

const MOCK_PREFIX = 'http://dragon.qa.qima-inc.com/mock-api';

const getCookie = (key) => {
  const cookieMap = {};
  window.global_data?.header?.cookie?.split('; ')?.forEach((c) => {
    if (c.includes('=')) {
      const p = c.split('=');
      cookieMap[p[0]] = p[1];
    }
  });
  return cookieMap[key];
};
class CacheUrl {
  constructor() {
    this.cache = {};
  }

  find(url, options) {
    const key = this.generateKey(url, options);
    return this.cache[key];
  }

  add(url, options, promise) {
    const key = this.generateKey(url, options);
    if (!this.cache[key]) {
      this.cache[key] = {
        promise,
        timer: null,
      };
    }
  }

  delete(url, options) {
    const key = this.generateKey(url, options);
    if (this.cache[key]) {
      this.cache[key].timer = setTimeout(() => {
        delete this.cache[key];
      }, 1000);
    }
  }

  generateKey(url, options) {
    return url + JSON.stringify(options);
  }
}

const cacheUrl = new CacheUrl();

export default class BaseApi {
  /**
   * ajax请求
   */
  // eslint-disable-next-line class-methods-use-this
  _request(url, options) {
    const throttle = options.throttle || false;
    delete options.throttle;
    const cacheResult = throttle ? cacheUrl.find(url, options) : null;
    let promise = null;
    if (cacheResult) {
      // 正在请求
      promise = cacheResult.promise;
    } else {
      promise = ajax(url, options);
      cacheUrl.add(url, options, promise);
    }

    return {
      then(resolve, reject) {
        return promise
          .then((res) => {
            const cache = cacheUrl.find(url, options);
            if (cache) {
              clearTimeout(cache.timer);
            }
            cacheUrl.delete(url, options);
            return Promise.resolve(res);
          })
          .catch((err) => {
            console.error('err', err);
            cacheUrl.delete(url, options);
            return Promise.reject(err);
          })
          .then(resolve, reject);
      },
      catch(reject) {
        cacheUrl.delete(url, options);
        return promise.catch(reject);
      },
      finally(callback) {
        callback();
        cacheUrl.delete(url, options);
        return Promise.resolve();
      },
    };
  }

  /**
   * ajax请求,参数直接传json
   */
  _requestByJson(url, json, options = {}) {
    let requestUrl = url;
    if (options.mock) {
      requestUrl = MOCK_PREFIX + (url.startsWith('/') ? '' : '/') + url;
      requestUrl = `${requestUrl + (requestUrl.indexOf('?') > 0 ? '&' : '?')}mock=${options.mock}`;
    }
    const data = { ...options.data };

    // typeof (null) == object 返回 true
    // [] installceof Object 返回 true
    // 所以判断对象需要双重判断
    if (typeof json === 'object' && json instanceof Object) {
      if (!__isServer) {
        Object.assign(json, { kdtId: window?.global_data?.kdtId });
      }
      data.json = JSON.stringify(json);
    }

    return this._request(requestUrl, {
      ...options,
      data,
    });
  }

  /** *
   * ajax请求，提供默认请求方式post
   * @param url
   * @param json
   * @param options
   * @returns {*}
   */
  _post(url, json = undefined, options = {}) {
    return this._requestByJson(url, json, { ...options, method: 'post' });
  }

  /** **
   * ajax请求，使用默认请求方式get
   * @param url
   * @param json
   * @param options
   * @returns {*}
   */
  _get(url, json = undefined, options = {}) {
    return this._requestByJson(url, json, { ...options, method: 'get' });
  }

  /**
   * 网管层请求
   * @param url
   * @param params
   * @param options
   */
  _requestGateway(url, params = {}, options = {}) {
    return this._requestByJson(`/gateway${url}`, params, {
      ...options,
      method: 'POST',
    });
  }

  /**
   * GraphQL 接口
   */
  async _graphqlApi(params, options = {}) {
    const data = params;
    if (!__isServer) {
      set(data, 'variables.params.kdtId', window?.global_data?.kdtId);
    }
    const tokenKeyNeed = 'i18n-c-token';
    return this._request('/gateway/api/graphql', {
      ...options,
      header: {
        ...(options.header || {}),
        [tokenKeyNeed]: getCookie(tokenKeyNeed),
      },
      contentType: 'application/json',
      data,
      method: 'POST',
    });
  }
}
