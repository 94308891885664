import styled, { css } from 'styled-components';

export const Style = styled.div`
  cursor: pointer;
  position: relative;
  color: ${(props) => props.theme.textColor};
  background-color: ${(props) => (props.isCard ? props.cardColor : 'initial')};
  display: flex;
  flex-direction: column;
  justify-content: space-between; // 两端对齐
  height: 100%;
  /* overflow: hidden; */
  border-radius: ${(props) => (props.isCard ? 'var(--border_radius_pd_card)' : 0)};
  .group-goods-card-image {
    border-radius: ${(props) => (props.isCard ? 0 : '')};
  }
  a {
    pointer-events: auto !important;
  }
  .group-goods-wish {
    opacity: 0;
  }
  &:hover {
    .group-goods-wish {
      z-index: 9;
      opacity: 1;
    }
  }
  .space {
    margin-top: ${(props) => props.theme.getSpace([4, 12])};
  }
  .bottom-container {
    margin-left: ${(props) => (props.isCard ? props.theme.getSpace([8, 16]) : 0)};
    margin-right: ${(props) => (props.isCard ? props.theme.getSpace([8, 16]) : 0)};
    margin-bottom: ${(props) => (props.isCard ? props.theme.getSpace([8, 16]) : 0)};
    .bottom-info-container {
      width: 100%;
      &.show-margin-top {
        margin-top: ${(props) => props.theme.getSpace([8, 12])};
      }
    }
  }
  .operate-btn {
    margin-top: ${(props) => props.theme.getSpace([14, 18])};
    button {
      width: 100% !important;
    }
    .zent-btn-primary {
      background: ${(props) => props.theme.colorButton} !important;
      border: 1px solid ${(props) => props.theme.colorButton} !important;
      color: ${(props) => props.theme.colorButtonText} !important;
    }
  }
  .tag-text {
    color: var(--color_activity_label);
    background: rgba(var(--color_activity_label_rgb), 0.1);
    padding: 2px;
    ${(props) => props.theme.getBaseSize(props.theme.h12)};
    border-radius: 1px;
  }

  .discount-price {
    .price-symbol,
    .price-integer,
    .price-decimal {
      color: ${(props) => props.priceColor};
      font-size: inherit;
    }
  }
  .group-design-section {
    .underlined-price {
      color: ${(props) => props.theme.hexToRGBA(props.titleColor, 0.6)} !important;
      .price-symbol,
      .price-integer,
      .price-decimal {
        color: ${(props) => props.theme.hexToRGBA(props.titleColor, 0.6)} !important;
      }
    }
  }

  .bottom-info {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    .vendor-name {
      color: ${(props) => props.theme.hexToRGBA(props.theme.colorBodyText, 0.6)} !important;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .sell-comment {
      max-width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      color: ${(props) => props.theme.hexToRGBA(props.theme.colorBodyText, 0.6)} !important;
      .comment-num:hover {
        color: var(--color_button);
        cursor: pointer;
      }
      .sell-num {
        max-width: 100%;
        display: flex;
        align-items: center;
      }
      .comment-num + .sell-num::before {
        content: '';
        width: 2px;
        height: 2px;
        border-radius: 50%;
        margin: 0 4px;
        background-color: ${(props) => props.theme.hexToRGBA(props.theme.colorBodyText, 1)};
        display: inline-block;
        vertical-align: middle;
      }
      .sell-goods-score + .sell-num::before {
        content: '';
        width: 2px;
        height: 2px;
        border-radius: 50%;
        margin: 0 4px;
        background-color: ${(props) => props.theme.hexToRGBA(props.theme.colorBodyText, 1)};
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
  .product-title-align-center {
    .bottom-info {
      flex-direction: column !important;
      align-items: center !important;
    }
    .comment-container {
      justify-content: center;
    }
    .sell-comment {
      margin-bottom: 4px;
      justify-content: center;
      align-items: center !important;
    }
    .title {
      text-align: center;
    }
  }
  .tag-item-inner {
    ${(props) => props.theme.getBaseSize(props.theme.h12)};
    height: ${(props) => props.theme.getSpace([15, 18])};
    line-height: ${(props) => props.theme.getSpace([15, 18])};
  }
  .goods-image-container {
    position: relative;
  }
  .sell-out-tag {
    width: ${(props) => props.theme.getSpace([78, 91, 96])};
    height: ${(props) => props.theme.getSpace([30, 35, 36])};
    line-height: ${(props) => props.theme.getSpace([30, 35, 36])};
    text-align: center;
    background-color: rgba(51, 51, 51, 0.6);
    color: #ffffff;
    font-size: ${(props) => props.theme.getSpace([12, 13, 14])};
    position: absolute;
    bottom: 0;
    right: 0;
  }
  @media screen and (max-width: 749px) {
    .bottom-info {
      flex-direction: column !important;
      align-items: flex-start !important;
      .sell-comment {
        flex-direction: column;
        align-items: flex-start !important;
        .comment-container {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;
        }
      }
    }
    .product-title-align-center {
      .sell-comment {
        align-items: center !important;
      }
    }
  }
`;

const getTitleLimitCSS = (textLineLimit) => {
  if (textLineLimit !== 'auto') {
    return css`
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: ${textLineLimit};
      line-clamp: ${textLineLimit};
      -webkit-box-orient: vertical;
    `;
  }
};

export const TitleStyle = styled.div`
  color: ${(props) => props.theme.hexToRGBA(props.theme.colorText, 1)};
  margin-top: ${(props) => props.theme.getSpace([8, 16])};
  ${(props) => getTitleLimitCSS(props.textLineLimit)};
`;

export const BadgeStyle = styled.div`
  position: absolute;
  z-index: 6;
  color: #fff;
  text-align: center;
  &.first,
  &.two {
    left: ${(props) => props.theme.getSpace([8, 16])};
    &.normal {
      .group-buy-content {
        width: ${(props) => props.theme.getSpace([48, 48])};
        height: ${(props) => props.theme.getSpace([46, 46])};
        padding-top: ${(props) => props.theme.getSpace([6, 6])};
        padding-left: ${(props) => props.theme.getSpace([8, 8])};
        padding-right: ${(props) => props.theme.getSpace([8, 8])};
        box-sizing: border-box;
      }

      .triangle {
      }
    }
    &.small {
      .group-buy-content {
        width: 30px;
        padding: 4px;
        padding-bottom: 1px;
        padding-top: 6px;
        box-sizing: border-box;
        .content-value {
          line-height: 10px;
        }
      }
    }
    .group-buy-content {
      background: ${(props) => props.theme.hexToRGBA(props.theme.colorSaleText, 1)};
      box-sizing: border-box;
      .content-value {
        ${(props) => props.theme.getBaseSize(props.theme.h11)};

        font-weight: 700;
      }
      .content-text {
        ${(props) => props.theme.getBaseSize(props.theme.h12)};
        font-weight: 700;
      }
    }
  }
  &.first {
    &.normal {
      .triangle {
        width: 0;
        height: 0;
        border: ${(props) => props.theme.getSpace([24, 24])} solid transparent;
        border-top: ${(props) => props.theme.getSpace([10, 10])} solid
          ${(props) => props.theme.hexToRGBA(props.theme.colorSaleText, 1)};
      }
    }
    &.small {
      .triangle {
        width: 0;
        height: 0;
        border: 15px solid transparent;
        border-top: 7px solid ${(props) => props.theme.hexToRGBA(props.theme.colorSaleText, 1)} !important;
      }
    }
  }
  &.two {
    .triangle {
      width: 0;
      height: 0;
      border-left: ${(props) => props.theme.getSpace([24, 24])} solid
        ${(props) => props.theme.hexToRGBA(props.theme.colorSaleText, 1)};
      border-right: ${(props) => props.theme.getSpace([24, 24])} solid
        ${(props) => props.theme.hexToRGBA(props.theme.colorSaleText, 1)};
      border-top: 0px solid ${(props) => props.theme.hexToRGBA(props.theme.colorSaleText, 1)};
      border-bottom: 8px solid transparent;
    }
  }
  &.three {
    left: 0;
    top: ${(props) => props.theme.getSpace([8, 16])};
    background: ${(props) => props.theme.hexToRGBA(props.theme.colorSaleText, 1)};
    width: ${(props) => props.theme.getSpace([56, 75])};

    text-align: center;
    line-height: ${(props) => props.theme.getSpace([24, 32])};
    .content-text {
      ${(props) => props.theme.getBaseSize(props.theme.h11)};
      padding: ${(props) => props.theme.getSpace([4, 8])} 0;
      font-weight: 700;
      /* transform: scale(0.83); */
      /* line-height: 16px; */
      /* padding: 4px; */
    }
  }
`;

const getAlignStyle = (alignText) => {
  // const { alignText } = props;
  if (alignText === 'left') {
    return 'flex-start';
  } else if (alignText === 'right') {
    return 'flex-end';
  } else {
    return 'center';
  }
};

export const TextStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => getAlignStyle(props.alignText)};
  margin: 0 ${(props) => (props.isCard ? props.theme.getSpace([8, 16]) : 0)};
`;
