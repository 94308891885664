/**
 * 请求封装工具
 *
 * @example
 *
 * import request from '../request';
 * request(api, {
 *   method: 'post',
 *   data: {}
 * });
 */
import superagent from 'superagent';
import { getLocale } from '../util/i18n';
import { getClientLanguage } from '../../helper/multi-language';
import { Notify } from 'zent';

// 网络状态码，非200
const handleRequestError = ({ url, err, reqData, statusCode }) => {
  // Notify.error(err?.message);
  // 打印日志
  console.error({
    url,
    reqData,
    statusCode,
  });
};
// 业务统一错误
const handleBusinessError = (code, message) => {
  const isLoginErrorCodes = ['271000101', '271000102', '271000103', '271000104', '271000105', '271000106', '271000107'];
  message && Notify.error(message);
  if (isLoginErrorCodes.indexOf(code) !== -1) {
    window.location.href = 'https://admin.allvalue.com/admin/user#/login';
  }
};
/** 接口错误 */
const handleOther = ({ url, data, res = {}, resolve, reject, config }) => {
  const { commonErrorHandle = true } = config;

  if (!res) {
    throw Error(`后端接口未返回结果：${url}`);
  }
  const { body = {} } = res || {};
  if (body && body?.code === 200) {
    const isDataExist = body?.data || body?.data === 0;
    // 成功
    resolve(isDataExist ? body?.data : {});
  } else if (commonErrorHandle) {
    console.log(`ajax文件=接口请求出错，详情：`, {
      url,
      traceid: res?.header?.['x-traceid'],
      body,
    });
    // 错误，是否走统一处理
    handleBusinessError(body?.code, body?.message);

    let configData = '';
    try {
      configData = JSON.stringify(data);
    } catch (e) {
      console.log('JSON.stringify handleOther error');
      configData = '';
    }
    reject({
      message: body ? body?.message : undefined,
      code: body ? body?.code : undefined,
      url,
      config: configData,
      traceid: res?.header?.['x-traceid'],
    }); // 为了业务方调用时逻辑走到finally。
  } else {
    if (__isServer) {
      resolve({
        url,
        reqData: data,
        desc: body ? body.message : undefined,
        message: body ? body.message : undefined,
      });
      return;
    }

    // 错误，业务自定义catch处理
    // eslint-disable-next-line
    reject({
      url,
      code: body ? body.code : undefined,
      reqData: data,
      desc: body ? body.message : undefined,
      message: body ? body.message : undefined,
    });
  }
};

const handleGraphql = ({ res, resolve, reject }) => {
  const { data, errors } = res?.body || {};
  if (errors?.length > 0) {
    const error = {
      code: errors[0].code,
      message: errors[0].message,
      errors,
    };
    handleBusinessError(error.code, error.message);
    reject(error);
  } else {
    resolve({
      ...data,
    });
  }
};

export default function ajax(api, config = {}) {
  const language = !__isServer ? window?.global_data?.shopSetting?.language : '';
  const ip = !__isServer ? window?.global_data?.ip : '';
  const locale = !__isServer ? getClientLanguage(window?.global_data) : getLocale(language);
  const url = api;
  let {
    method = 'get', // 请求类型
    contentType,
  } = config;
  const {
    data = {}, // 请求数据
  } = config;

  method = method.toUpperCase();
  if (method === 'POST' && typeof contentType === 'undefined') {
    contentType = 'application/x-www-form-urlencoded'; // 参数类型
  }
  return new Promise((resolve, reject) => {
    const serviceChain = config?.header?.['x-service-chain'];
    const customI18nRecaptchaToken = config?.header?.['custom-i18n-recaptcha-token'];
    const i18nCToken = config?.header?.['i18n-c-token'];
    if (!url) return false;
    const realUrl = __isServer ? `http://127.0.0.1:8201${url}` : url;
    const req = superagent[method.toLowerCase()](realUrl, data);
    if (contentType) {
      req.set('Content-Type', contentType);
    }
    if (locale) {
      req.set('locale', locale);
    }
    if (ip) {
      req.set('ip', ip);
    }
    if (serviceChain) {
      req.set('x-service-chain', serviceChain);
    }
    if (customI18nRecaptchaToken) {
      req.set('custom-i18n-recaptcha-token', customI18nRecaptchaToken);
    }
    if (i18nCToken) {
      req.set('i18n-c-token', i18nCToken);
    }
    req.set('custom-device-type', '10');

    req
      .timeout(10000)
      .withCredentials()
      .end((err, res) => {
        if (err) {
          // http错误
          handleRequestError({
            url,
            err,
            reqData: data,
            statusCode: res?.statusCode,
          });
          reject(err);
        } else if (url.includes('/api/graphql')) {
          handleGraphql({ res, resolve, reject });
        } else {
          handleOther({ url, data, res, resolve, reject, config });
        }
      });
  });
}

export function requestDataWrap(data) {
  return {
    data: {
      json: JSON.stringify(data),
    },
  };
}

export function requestPostDataWrap(data) {
  return {
    method: 'post',
    data: {
      json: JSON.stringify(data),
    },
  };
}

export function requestRawWrap(data) {
  return {
    isRaw: true,
    data: {
      json: JSON.stringify(data),
    },
  };
}
