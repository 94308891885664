import React from 'react';
import { PhotoProvider, PhotoConsumer } from 'react-photo-view';
import { ToolbarStyle, PreviewStyle } from './style';
import Image from 'components/base/image';

function PhotoViewMobile({
  photoImages = [],
  onReturn,
  onDelete,
  renderItem,
  renderTrigger = () => { },
  handle,
  ...props
}) {
  let previewMaskRef = null;

  const onReturnClick = (index) => {
    if (onReturn) {
      onReturn(index);
    }

    previewMaskRef.handleClose();
  };

  const onDeleteClick = (index) => {
    if (onDelete) {
      onDelete(index);
    }

    previewMaskRef.handleClose();
  };

  const toolbarRender = ({ images, index }) => {
    return (
      <ToolbarStyle className='preview-tool-bar'>
        <span onClick={() => onReturnClick(index)} className='preview-back-icon'>
          <svg className='action-icon return-icon' aria-hidden='true' height='16px' width='16px'>
            <use xlinkHref='#iconic-zuoyouqiehuan' />
          </svg>
        </span>
        <span className='preview-counter'>
          {index + 1} / {images.length}
        </span>
        <span onClick={() => onDeleteClick(index)} className='preview-item-action'>
          {onDelete ? (
            <svg className='action-icon delete-icon' aria-hidden='true' height='16px' width='16px'>
              <use xlinkHref='#iconshanchu' />
            </svg>
          ) : null}
        </span>
      </ToolbarStyle>
    );
  };

  const imageListClass = `review-image-list mobile-review-image image-list-${photoImages.length}`;

  return (
    <PhotoProvider
      ref={(el) => {
        previewMaskRef = el;
      }}
      photoClosable
      {...props}
      introVisible={false}
      toolbarRender={toolbarRender}
    >
      <ul className={imageListClass}>
        {photoImages.map((item, index) => (
          <PhotoConsumer key={index} src={item} intro={item}>
            {renderItem ? (
              renderItem(item, index)
            ) : (
              <li className='review-image-item'>
                <Image src={item} alt='PhotoConsumer' alt={handle} />
              </li>
            )}
          </PhotoConsumer>
        ))}
        {renderTrigger()}
      </ul>
      <PreviewStyle />
    </PhotoProvider>
  );
}

export default PhotoViewMobile;
