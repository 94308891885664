import React, { useEffect, useMemo, useRef } from 'react';
import { Icon as HtIcon } from 'components/base';
import cn from 'classnames';
import { StyleAdditionalItems, StyleCounter } from './style';
import get from 'lodash/get';
import { getAdditionalItemRulesValidType } from './utils';
import { HtPrice } from 'cpn/goods-price';
import { Notify } from 'zent';

const AdditionalItemRulesIconMap = {
  error: 'iconic-jinggao',
  success: 'iconic-chenggong',
};

const Counter = ({ count = 0, onAdditionalItemSelect, optionRule, iconType = '', max }) => {
  const onQuantityChange = (e, plusCount) => {
    e.stopPropagation();
    onAdditionalItemSelect(plusCount, optionRule);
  };

  return (
    <StyleCounter>
      {count > 0 && (
        <>
          <div className='step-btn minus-one' onClick={(e) => onQuantityChange(e, -1)}>
            <HtIcon size='small' name='icona-01jichu_jian' />
          </div>
          <div className='count notranslate'>{count}</div>
        </>
      )}
      <div
        className={cn('step-btn plus-one', { disabled: count >= optionRule?.maxBuyQuota || count >= max })}
        onClick={(e) => onQuantityChange(e, 1)}
      >
        <HtIcon size='small' name='icona-01jichu_jia' />
      </div>
    </StyleCounter>
  );
};

const AdditionalItemRules = ({ min, max, type = 'default', required, multi, $fm }) => {
  const isShowMin = !!min;
  const isShowMax = !!max && required === 0 && multi === 1;

  const text = useMemo(() => {
    let t = '';
    if (isShowMin) {
      t = $fm('0e1ac9f46cfe43c5816fa7edd296a60c', '最少{min}份', { min });
    }
    if (isShowMin && isShowMax) {
      t = `${t}丨`;
    }
    if (isShowMax) {
      t = `${t}${$fm('1cc6c1813e7646a78b77a701d008a560', '最多{max}份', { max })}`;
    }
    return t;
  }, [isShowMin, isShowMax]);

  if (multi === 1 && min === undefined && max === undefined) {
    return null;
  }

  return (
    <div className={cn('additional-item-rules', type)}>
      {type !== 'default' && <HtIcon name={AdditionalItemRulesIconMap[type]} size='mini' />}
      <div className='text'>{text}</div>
    </div>
  );
};

const AdditionalItem = ({ additionalItem, cart, onGoodsAdditionalItemSelect, max, title = '', $fm, size = '' }) => {
  const { optionRules = [], propId, propRule } = additionalItem;
  const itemsCount = cart?.goodsAdditionalItems?.[propId] || {};
  const allPriceIsZeroYuan = optionRules.find((option) => option.price !== 0);
  const allIsNotSingle = optionRules.find((option) => option.maxBuyQuota !== 1);
  const isNotShowPrice = !allPriceIsZeroYuan;
  const isNotShowCounter = !allIsNotSingle;

  // 添加配料数量
  const onAdditionalItemSelect = (plusCount, optionRule) => {
    const { maxBuyQuota, optionId, optionTitle, isActivated } = optionRule;
    if (isActivated === 0) {
      if (plusCount === -1) {
        onGoodsAdditionalItemSelect({ propId, ...optionRule, propRule }, plusCount);
        return;
      }
      Notify.warn($fm('8feaa2fe2d3144d29fe9f7b80966ad8d', '已售罄'));
      return;
    }
    const curQuantity = get(cart, `goodsAdditionalItems[${propId}][${optionId}].quantity`, 0);

    if (plusCount === 1 && curQuantity >= max) {
      Notify.warn(
        $fm('9cee12e5bd3b4d21b889e4782348b436', '[{optionTitle}]最多{maxBuyQuota}份', {
          optionTitle: title,
          maxBuyQuota: max,
        }),
      );
      return;
    }

    if (plusCount === 1 && curQuantity >= maxBuyQuota) {
      if (!isNotShowCounter) {
        Notify.warn(
          $fm('9cee12e5bd3b4d21b889e4782348b436', '[{optionTitle}]最多{maxBuyQuota}份', { optionTitle, maxBuyQuota }),
        );
      }
      return;
    }
    onGoodsAdditionalItemSelect({ propId, ...optionRule, propRule }, plusCount);
  };

  return optionRules.map((optionRule) => {
    const { isActivated, optionId, price: salePrice = '' } = optionRule;
    const { quantity: count } = itemsCount[optionId] || {};
    const price = !isNotShowPrice && !!salePrice ? salePrice : '';
    return (
      <div
        className={cn('additional-item', size, { selected: count > 0 }, { disabled: isActivated === 0 })}
        onClick={(e) => {
          // e.stopPropagation();
          // 不显示步进器的时候，需要支持反选
          const plusCount = isNotShowCounter && count === 1 ? -1 : 1;
          onAdditionalItemSelect(plusCount, optionRule);
        }}
        key={`additional-item-${optionId}`}
      >
        <div className={cn('detail', { 'no-price': isNotShowPrice })}>
          <div className='title'>
            <div>{optionRule.optionTitle}</div>
          </div>
          <div className='price-count'>
            <div className='price'>
              {price ? (
                <>
                  <div className='plus-symbol'>+</div>
                  <HtPrice price={price} />
                </>
              ) : (
                ''
              )}
            </div>
            {!isNotShowCounter && (
              <Counter
                onAdditionalItemSelect={onAdditionalItemSelect}
                count={count}
                optionRule={optionRule}
                iconType=''
                max={max}
              />
            )}
          </div>
        </div>
      </div>
    );
  });
};

const AdditionalItems = (props) => {
  const { attachProps = [], intl, cart, isInitNeedCheck = false, onGoodsAdditionalItemSelect, size = '' } = props;
  // const initialData = window?.global_data ?? {};
  // const currency = initialData?.shopSetting?.currency;
  const $fm = (id = '', defaultMessage = '', value = {}) => {
    return id ? intl?.formatMessage({ id, defaultMessage }, value) : defaultMessage;
  };

  if (attachProps.length === 0) {
    return <></>;
  }

  return (
    <StyleAdditionalItems>
      {attachProps.map((additionalItem) => {
        const { propRule = {}, propId, title } = additionalItem;
        const { required, multi, maxOptionQuota } = propRule;
        const { valid: additionalItemRulesValidType } = getAdditionalItemRulesValidType({
          propId,
          propRule,
          cart,
          isInitNeedCheck,
        });
        return (
          <div key={propId} id={`additional-items-${propId}`}>
            <div className={cn('additional-rule', { en: false })}>
              <div className='additional-item-title'>{additionalItem?.title}</div>
              <AdditionalItemRules
                min={required === 1 ? 1 : required === 0 && multi === 0 ? 0 : propRule.minOptionQuota}
                max={maxOptionQuota}
                required={required}
                multi={multi}
                type={additionalItemRulesValidType}
                $fm={$fm}
              />
            </div>
            <div className='additional-items-detail'>
              <AdditionalItem
                additionalItem={additionalItem}
                cart={cart}
                onGoodsAdditionalItemSelect={onGoodsAdditionalItemSelect}
                max={maxOptionQuota}
                title={title}
                $fm={$fm}
                size={size}
              />
            </div>
          </div>
        );
      })}
    </StyleAdditionalItems>
  );
};

export default AdditionalItems;
