import styled from 'styled-components';

export default styled.div`
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 9999;
  /* background: #fff; */
  background-color: var(--color_card, #fff);
  min-width: 300px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-radius: var(--border_radius_card) 0 0 var(--border_radius_card);
  overflow: hidden;
  @keyframes showShoppingCart {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  @keyframes hideShoppingCart {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(100%);
    }
  }
  .cart-close,
  .cart-operation {
    flex-shrink: 0;
    flex-grow: 0;
  }
  .shopping-middle-content {
    flex: 1;
    width: 100%;
    overflow-y: auto;
    position: relative;
    background: rgba(var(--color_body_text_rgb), 0.04);

    .cart-goods-item-info {
      &:not(:has(.discount-code-detail)) {
        .custom-sku-wrapper {
          margin-bottom: 8px;
        }
      }
    }
    .cart-activity-card + .cart-activity-card {
      margin-top: 6px;
    }
    .hunt-prompt-wrapper {
      .hunt-prompt {
        .discount-desc {
          font-size: var(--base-font-14-12) !important;
        }
        .hunt-prompt-right-wrapper {
          margin: 0 !important;
        }
        .hunt-prompt-right {
          .link-text {
            font-weight: 400;
            /* color: var(--color_button) !important; */
            font-size: var(--font-14-12) !important;
          }
          .icon-right {
            /* fill: var(--color_button) !important; */
          }
        }
      }
    }
  }
  &.show-cart-animate {
    animation: showShoppingCart 0.3s forwards;
  }
  &.close-cart-animate {
    animation: hideShoppingCart 0.3s forwards;
  }
  .m24 {
    margin: 0 var(--pm-24-16);
  }
  .border-t {
    border-top: 1px solid var(--color_borders);
  }
  .goods-accept-payment-logo {
    margin-top: 0px !important;
  }
  @media screen and (min-width: 750px) {
    width: 480px !important;
  }

  @media screen and (max-width: 750px) {
    width: 100%;
    .hunt-prompt-wrapper {
      .hunt-prompt {
        &:first-child {
          margin-top: 16px !important;
        }
        margin-bottom: 16px !important;
      }
    }
    .icon-wrapper {
      display: none;
    }
  }
`;

export const CartStyle = styled.div`
  /* border-bottom: 1px solid var(--color_borders); */
  .cart-content-wrapper {
    padding: var(--pm-6-0) 0 var(--pm-24-18);
    background-color: var(--color_card, #fff);
    min-width: 300px;
    box-sizing: border-box;
  }
  /* .is-sidebar-wrapper {
        padding-top: 8px;
    } */
  .cart-list {
    padding: 0 24px;
    overflow-y: auto;
    /* .cart-goods-item-wrapper {
            border-top: 1px solid var(--color_borders);
        } */
  }
  .img-container {
    display: inline !important;
  }
  .delete-wrapper {
    flex-direction: row-reverse;
    line-height: 1;
  }
  .underlined-price {
    .price-symbol,
    .price-integer {
      color: rgba(var(--color_body_text_rgb), 0.4) !important;
    }
  }
  .cart-list-wrapper + .cart-list-wrapper {
    &::before {
      content: '';
      height: 6px;
      width: calc(100% + 48px);
      background: rgba(var(--color_body_text_rgb), 0.04);
      margin-left: -24px;
      display: block;
    }
  }
  /* .gift-list:not(:empty) {
        padding-bottom: 24px;
    } */
  @media screen and (max-width: 750px) {
    .cart-list {
      padding: 0 16px;
    }
    .cart-list-wrapper + .cart-list-wrapper {
      &::before {
        width: calc(100% + 32px);
        margin-left: -16px;
      }
    }
  }
`;

export const CloseStyle = styled.div`
  &.cart-close {
    text-align: right;
    display: flex;
    justify-content: space-between;
    padding: 24px;
    position: relative;
    align-items: center;
    &::after {
      position: absolute;
      content: '';
      left: 0;
      bottom: 0;
      width: 100%;
      height: 0px;
      border-bottom: 1px solid var(--color_borders);
      transform: scaleY(0.5);
    }
    .sidebar-goods-number {
      font-size: var(--base-font-20-16);
      font-family: var(--font-stack-header);
      font-style: var(--font-style-header);
      font-weight: var(--font-weight-header);
    }
    .delete-icon-close {
      cursor: pointer;
      width: var(--font-24-16);
      height: var(--font-24-16);
      fill: var(--color_body_text);
      cursor: pointer;
      display: inline-block;
      vertical-align: bottom;
    }
  }
  @media screen and (max-width: 750px) {
    &.cart-close {
      padding: 24px 16px 16px;
    }
  }
`;

export const OperationStyle = styled.div`
  position: relative;
  &::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 0px;
    border-bottom: 1px solid var(--color_borders);
    transform: scaleY(0.5);
  }
  .icon-wrapper {
    padding: 16px 0;
    /* border-top: 1px solid var(--color_borders); */
  }
  &.cart-operation {
    text-align: center;
    display: flex;
    flex-direction: column;
    /* position: absolute; */
    padding: 0 var(--pm-24-16);
    /* bottom: 0px; */
    padding-bottom: var(--pm-24-16);
    background-color: var(--color_card, #fff);
    z-index: 999;
    box-sizing: border-box;
    width: 100%;
    .cart-operation-content {
      width: 100%;
    }
    button {
      height: 44px;
      width: 100%;
      a {
        color: #fff;
        display: block;
        height: 100%;
        width: 100%;
      }

      &.zent-btn-primary {
        margin-bottom: var(--pm-16-10);
        background: #041b4a;
      }
    }
    .show-cart {
      a {
        color: var(--color_small_button_text_border);
      }
      cursor: pointer;
    }
  }
  @media screen and (max-width: 750px) {
    &.cart-operation {
      padding-top: var(--pm-24-16);
      .zent-btn-primary {
        margin-bottom: 16px !important;
      }
    }
  }
`;
