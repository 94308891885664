import React, { useState, useEffect } from 'react';
import { DiscountGiftWrapper } from 'cpn/discount';
import { GiftCartItem } from './cart-gift-item';
import * as cartHelper from 'helper/cart';
import pick from 'lodash/pick';
import discountApi from 'api/discount';
import { formateGoodsToSku } from '../../../../hook/cart/helper';
import { DiscountType, ApplyDiscountStatus } from 'const/discount';
import { useIntl } from 'react-intl';
import HuntPrompt from 'cpn/hunt-prompt';
import HuntTag from 'cpn/hunt-tag';
import { CartItem } from '../cart-goods-list/cart-goods-list-item';

import { Style } from './style';

const CartGiftList = (props) => {
  const { itemProps, currency, promotionId, giftGoods = [], discountQuantity, isDrawer = false, domClassName } = props;

  const intl = useIntl();

  const giftQuantity = giftGoods.reduce((total, currentValue) => {
    const quantity = currentValue?.quantity || 0;
    return total + quantity;
  }, 0);

  const onViewGiftPage = () => {
    window.open(`/gift/goods?activityId=${promotionId}`);
  };

  return (
    <Style className='hunt-prompt-wrapper'>
      <HuntPrompt
        className='gift-prompt'
        linkText={intl.formatMessage({
          id: 'go_to_select',
          defaultMessage: '去选择',
        })}
        onLink={onViewGiftPage}
        desc={
          <div className='gift-tag-wrapper'>
            <HuntTag className='gift-tag'>
              {intl.formatMessage({
                id: 'goods_with_promotion',
                defaultMessage: '优惠商品',
              })}
            </HuntTag>
            <span className='select-tip'>
              {intl.formatMessage(
                {
                  id: '94f99b6bdafc40afb1572fb61f58a9cb',
                  defaultMessage: `已选{current}/{total}`,
                },
                {
                  current: giftQuantity,
                  total: discountQuantity,
                },
              )}
            </span>
          </div>
        }
      />
      {!!giftGoods.length && (
        <div className='gift-list'>
          {giftGoods.map((item, index) => {
            return (
              <CartItem
                item={item}
                index={index}
                // key={`${item?.variantId}-${item?.goodsId}-${item.quantity}-${item.giftGoodsFlag}`}
                key={item.itemKey}
                currency={currency}
                isExpiredGoodsStatus={false}
                {...itemProps}
                disabledChangeNum
                domClassName={domClassName}
              />
            );
          })}
        </div>
      )}
    </Style>
  );
};

export default CartGiftList;
