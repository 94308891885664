import React, { useEffect, useState } from 'react';
import SkuSelect from '../sku-select';
import Style, { PriceStyle } from './style';
import { HtGoodsDetailPrice, HtGoodsSkuPrice } from 'cpn/goods-price';
import goodsApi from 'api/goods';
import { withRouter } from 'react-router-dom';
import View from 'components/base/view';
import { getTotalAttachPropsPrice } from 'cpn/sku-list/additional-items/utils';

const BuyNow = (props) => {
  const {
    kdtId,
    goodsId,
    triggerSkuBlock,
    setGoodsMultipleSkuStatus,
    skuSelectRef,
    history,
    enablePaymentButton,
    setSkuPageCompleteStatus,
    currency,
    extra = {},
    size = '',
  } = props;
  const [goodsDetail, setGoodsDetail] = useState(null);
  const [cart, setCart] = useState({});
  const getGoodsDetail = () => {
    goodsApi
      .getGoodsById({
        kdtId: kdtId || global_data?.kdtId,
        goodsId,
        withSeo: false,
        withReview: false,
        withWishList: false,
        withDescription: false,
      })
      .then((res) => {
        setGoodsDetail({
          ...extra,
          ...res,
        });
        const { options = [], attachProps = [] } = res;
        if (setGoodsMultipleSkuStatus) setGoodsMultipleSkuStatus(options.length > 0 || attachProps.length > 0);
      });
  };

  useEffect(() => {
    if (goodsId) {
      getGoodsDetail();
    }
  }, []);

  const onSkuChange = (currentSelectSkuInfo) => {
    setCart(currentSelectSkuInfo);
  };

  const attachPropsPrice = getTotalAttachPropsPrice(cart.goodsAdditionalItems);

  return (
    <Style>
      {goodsDetail && (
        <div className='sku-container animate-sku'>
          <div className='sku-block'>
            <p className='sku-close-line'>
              <PriceStyle className='goods-price'>
                <View className='goods-price-size'>
                  {!cart?.variantId ? (
                    <HtGoodsDetailPrice attachPropsPrice={attachPropsPrice} goodsDetail={goodsDetail} size='small' />
                  ) : (
                    <HtGoodsSkuPrice
                      attachPropsPrice={attachPropsPrice}
                      price={(cart?.price || 0) + attachPropsPrice}
                      bestPriceInfo={cart?.bestPriceInfo}
                      compareAtPrice={cart?.compareAtPrice}
                      discountInfo={cart?.discountInfo}
                      memberInfo={cart?.memberInfo}
                      variantId={cart?.variantId}
                      size='small'
                    />
                  )}
                </View>
              </PriceStyle>
              <svg
                className='close-buy-now-icon'
                onClick={(event) => {
                  triggerSkuBlock(false);
                  event.stopPropagation();
                }}
                aria-hidden='true'
              >
                <use xlinkHref='#iconic-shanchu' />
              </svg>
            </p>
            <SkuSelect
              innerRef={skuSelectRef}
              currency={currency}
              goodsDetail={goodsDetail}
              goodsId={goodsId}
              triggerSkuBlock={triggerSkuBlock}
              enablePaymentButton={enablePaymentButton}
              enableCartButton={!enablePaymentButton}
              history={history}
              setSkuPageCompleteStatus={setSkuPageCompleteStatus}
              onSkuChange={onSkuChange}
              size={size}
            />
          </div>
        </div>
      )}
    </Style>
  );
};

export default withRouter(BuyNow);
