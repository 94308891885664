import styled from 'styled-components';

export default styled.div`
  .stepper-container {
    display: flex;
    width: 120px;
    justify-content: center;
    align-items: stretch;
    border-radius: var(--border_radius_form);
    border: 1px solid var(--color_text_field_border, #ececec);
    background: var(--color_text_field, rgba(255, 255, 255, 0));
    color: var(--color_text_field_text);
    .minus-btn,
    .plus-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 34px;
      height: 34px;
      flex-shrink: 0;
      cursor: pointer;
      svg {
        width: 16px;
        height: 16px;
        fill: var(--color_text_field_text);
      }
      &.disabled {
        cursor: not-allowed;
        svg {
          fill: var(--color_text_field_border);
        }
      }
    }
    .stepper-input-inner {
      flex: 1;
      padding: 0 10px;
      width: 0;
      text-align: center;
      color: var(--color_text_field_text);
      outline: none;
      border: none;
      background-color: transparent;
      &.disabled {
        cursor: not-allowed;
        color: var(--color_text_field_border);
      }
    }
    input[type='number'] {
      -moz-appearance: textfield; /* Firefox */
      appearance: textfield;
    }
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none; /* Safari */
      margin: 0;
    }
  }
`;
